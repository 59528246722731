<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, errors }"
  >
    <v-form
      class="mt-3"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <v-card>
        <v-card-title>{{ element ? 'Update' : 'New' }} custom material</v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            name="Name"
            rules="required"
          >
            <v-text-field
              v-model="formData.name"
              data-vv-name="element name"
              :error-messages="errors"
              label="Name"
              placeholder="Choose a name for the custom material"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Category"
            rules="required"
          >
            <v-select
              v-model="formData.category"
              data-vv-name="category"
              class="category-selected"
              :error-messages="errors"
              label="Category"
              placeholder="Select a category"
              :items="categories"
              item-value="name"
              item-text="name"
            >
              <template #item="{ item }">
                <!-- <BrandLogo
                  v-if="item.brand"
                  :brand="item.brand"
                  size="35"
                  class="mr-3"
                  style="background: yellow;"
                /> -->
                <span class="category-dropdown-item">{{ item.name }}</span>
              </template>
            </v-select>
          </ValidationProvider>
          <div class="d-flex mt-2">
            <ValidationProvider
              v-slot="{ errors }"
              name="CO₂e per unit"
              rules="required|min_value:0.00001"
            >
              <v-text-field
                v-model="co2PerUnit"
                class="pr-2"
                type="number"
                data-vv-name="CO₂e per unit"
                :error-messages="errors"
                label="CO₂e per unit"
                placeholder=" "
                suffix="kgCO₂e"
              />
            </ValidationProvider>
            <v-input>
              <template v-slot:prepend>
                <span class="d-block text-xs-center mt-5">per</span>
              </template>
              <ValidationProvider
                v-slot="{ errors }"
                name="CO₂e measure by"
                rules="required"
              >
                <v-select
                  v-model="formData.co2MeasuredPer"
                  :disabled="!!element"
                  data-vv-name="co2 measured by"
                  :error-messages="errors"
                  :items="unitMappings.filter(x => x.system === systemOfMeasurement).map(x => x.symbol)"
                />
              </ValidationProvider>
            </v-input>
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            name="Element measured by"
            :rules="{required: true, max_length: 3}"
          >
            <v-select
              v-model="formData.dimensions"
              :disabled="!!element"
              :error-messages="errors"
              label="Element measured by"
              placeholder="Select dimensions"
              hint="Select up to 3 dimension sets"
              persistent-hint
              multiple
              chips
              deletable-chips
              :items="dimensionOptions[co2MeasuredPerType]"
            />
          </ValidationProvider>
          <div
            v-if="co2MeasuredPerType === 'weight' && (formData.dimensions[0] !== 'weight' || formData.dimensions.length > 1)"
            class="d-flex"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Element Density"
              rules="required|min_value:0.0001"
            >
              <v-text-field
                v-model="formData.density"
                class="mr-4"
                type="number"
                data-vv-name="density"
                :error-messages="errors"
                label="Element density"
                placeholder="density"
                suffix="kg/m3"
              />
              <FormErrors errors="errors" />
            </ValidationProvider>
          <!--          <ValidationProvider-->
          <!--            v-slot="{ errors }"-->
          <!--            name="Density Units"-->
          <!--            rules="required"-->
          <!--          >-->
          <!--            <v-select-->
          <!--              v-model="formData.densityUnits"-->
          <!--              data-vv-name="density units"-->
          <!--              :error-messages="errors"-->
          <!--              label="Density units"-->
          <!--              placeholder="units"-->
          <!--              :items="['lb/ft3', 'kg/m3']"-->
          <!--            />-->
          <!--          </ValidationProvider>-->
          </div>
          <div
            v-if="element"
            class="ma-4 mb-0"
            style="text-align:center"
          >
            <b>Edits made to existing custom elements will be reflected across all project instances.</b>
          </div>
        </v-card-text>
        <v-card-actions class="py-4 px-5">
          <v-btn
            color="red"
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="shamrock white--text"
            elevation="0"
            type="submit"
          >
            {{ element ? 'Update' : 'Create' }} element
          </v-btn>
        </v-card-actions>
      <!-- FOR DEBUGGING ONLY: -->
      <!-- <div>
        <div v-for="([key, value], index) in Object.entries(element)" :key="index">
          <strong>{{ key }}</strong>: {{ value }}
        </div>
      </div> -->
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { mapState as mapGlobalState, mapGetters as mapGlobalGetters, mapActions, createNamespacedHelpers } from 'vuex'
import firstLetterUppercase from '@/helpers/firstLetterUppercase'
import getUniqueRef from '@/helpers/getUniqueRef'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import FormErrors from '@/components/form/FormErrors'
const { mapActions: mapElementActions } = createNamespacedHelpers('elements')

export default {
  name: 'CustomMaterialForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    FormErrors
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    category: {
      type: String,
      default: null
    },
    categories: {
      type: Array,
      default: () => []
    },
    element: {
      type: Object,
      default: () => null
    }
  },
  data () {
    const formData = this.element || ({
      id: null,
      name: '',
      category: null,
      co2PerUnit: 0,
      co2MeasuredPer: 'unit',
      density: undefined,
      dimensions: []
    })
    // set the co2PerUnit variable to the number baked into the formula
    formData.co2PerUnit = (this.element?.formulaForOneTime?.split(' ')?.[2] || 0)
    return {
      dictionary: {
        fields: {
          'Element measured by': {
            required: () => 'Element must have at least one dimension set',
            max_length: 'Element cannot have more than 3 dimension sets'
          },
          'Co2 per unit': {
            min_value: 'Co2 per unit must be more than zero'
          },
          'Element Density': {
            min_value: 'Density must be more than zero'
          }
        }
      },
      formData,
      dimensionOptions: {
        length: ['length'],
        area: [
          'length x width',
          'area'
        ],
        volume: [
          'length x width x height',
          'length x width x depth',
          'length x width x thickness',
          'area x depth',
          'volume'
        ],
        weight: [
          'length x width x height',
          'length x width x depth',
          'length x width x thickness',
          'area x depth',
          'weight'
        ],
        count: ['units']
      }
    }
  },
  computed: {
    ...mapGlobalState(['systemOfMeasurement']),
    ...mapGlobalGetters(['unitMappings', 'getEquivalentUnits']),

    co2MeasuredPerType () {
      return this.unitMappings.find(x => x.symbol === this.formData.co2MeasuredPer).type
    },
    toSi () {
      const { toSi } = this.unitMappings.find(x => x.symbol === this.formData.co2MeasuredPer)
      return toSi || 1
    },
    co2PerUnit: {
      get () {
        return (this.formData.co2PerUnit || 0) / this.toSi
      },
      set (val) {
        this.formData.co2PerUnit = (val || 0) * this.toSi
      }
    },
    elementData () {
      const { name, category, density } = this.formData
      const { toSi, type } = this.unitMappings.find(x => x.symbol === this.formData.co2MeasuredPer)
      // find si unit
      const co2MeasuredPer = this.unitMappings.find(x => x.type === type && x.toSi === 1).symbol
      // calculate co2 per unit after si conversion
      const co2PerUnit = this.formData.co2PerUnit * toSi
      // get formula
      const formulaForOneTime = `$qty * ${co2PerUnit}`
      // get layouts from dimension sets
      const layouts = this.formData.dimensions.map(str => {
        const existingRefs = []
        return {
          dimensions: str.split(' x ').map((descriptor, index) => {
            // FOR EACH DIMENSION:
            // get type
            let type
            if (descriptor === 'units') {
              type = 'count'
            }
            if (!type) {
              const found = this.unitMappings.find(x => x.type === descriptor)
              type = found && found.type
            }
            if (!type) {
              type = 'length'
            }
            // get units
            let units
            if (descriptor === 'units') {
              units = {
                metric: { symbol: 'quantity', toSi: 1 },
                imperial: { symbol: 'quantity', toSi: 1 }
              }
            } else if (['width', 'height', 'depth', 'thickness'].includes(descriptor)) { // small units for third dimension (width, height, thickness)
              units = {
                metric: { symbol: 'cm', toSi: 0.01 },
                imperial: { symbol: 'in', toSi: 0.0254 }
              }
            } else {
              units = {}
              const getMetric = () => {
                const { symbol, toSi } = this.unitMappings.find(x => x.type === type && x.toSi === 1)
                return { symbol, toSi }
              }
              units.metric = getMetric()
              const getImperial = () => {
                const { symbol, toSi } = this.getEquivalentUnits(units.metric.symbol)
                return { symbol, toSi }
              }
              units.imperial = getImperial()
            }
            // get label
            const label = firstLetterUppercase(descriptor)
            // get unique ref for formulae
            const ref = getUniqueRef(label, existingRefs)
            existingRefs.push(ref)
            return {
              type,
              units,
              valueRange: type === 'length' ? [0, 100] : undefined,
              value: 0,
              ref,
              label,
              editable: true
            }
          })
        }
      })
      return {
        type: 'material',
        ...this.formData, // merge in the old element data
        name,
        category,
        co2MeasuredPer,
        formulaForOneTime,
        layouts,
        density
      }
    }
  },
  watch: {
    co2MeasuredPerType: {
      immediate: true,
      handler (type) {
        this.formData.dimensions = this.dimensionOptions[type].slice(0, 1)
      }
    },
    visible (val) {
      if (!val) {
        this.$refs.observer.reset()
      }
    },
    category: {
      immediate: true,
      handler (val) {
        this.$set(this.formData, 'category', val)
      }
    }
  },
  async created () {
    localize('en', this.dictionary)
  },
  methods: {
    ...mapActions(['showSnackbar']),
    ...mapElementActions(['getPredictedLayoutFormula']),

    async onSubmit () {
      // add formulas to each element layout
      const element = { ...this.elementData }
      element.layouts = await Promise.all(element.layouts.map(async layout => {
        const res = await this.getPredictedLayoutFormula({
          dimensions: layout.dimensions,
          co2MeasuredPer: element.co2MeasuredPer
        })
        if (res.error) {
          this.showSnackbar({ color: 'error', text: 'Sorry, there was an error. Please try again..' })
          throw new Error()
        }
        return { ...layout, formula: res.formula }
      }))
      if (element._id) {
        this.$emit('update-element', element)
      } else {
        this.$emit('create-element', element)
      }
      this.$refs.observer.reset()
    }
  }
}
</script>

<style lang="scss" scoped>

.category-selected * {
  text-transform: capitalize;
}
.category-dropdown, .category-dropdown-item {
  text-transform: capitalize;
}
</style>
