<template>
  <div class="element-wrapper flex-grow-1 my-5">
    <Element
      :element="element"
      :requested-layout="element.activeLayout"
      :enable-zero-alerts="hasBeenEditedOnce"
      :deleting="deleting"
      :switching-layout="switchingLayout"
      @update-dimension-value="e => updateDimensionValue(e)"
      @update-element-options="e => updateElementOptions(e)"
      @toggle-dimension-manual-mode="e => toggleDimensionManualMode(e)"
      @layout-changed="i => switchLayout(i)"
      @delete-element="deleteElement()"
    />
  </div>
</template>

<script>
import Element from '@/components/application/element/Element'
import { mapState, mapActions, createNamespacedHelpers } from 'vuex'
import { captureException } from '@sentry/vue'

const { mapGetters: mapVersionGetters } = createNamespacedHelpers('projects/versions')

export default {
  name: 'CalculatorElement',
  components: {
    Element
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasBeenEditedOnce: false,
      deleting: false,
      switchingLayout: false
    }
  },
  computed: {
    ...mapState(['systemOfMeasurement']),
    ...mapVersionGetters(['version'])
  },
  watch: {
    isVisible (e) {
      this.hasBeenEditedOnce = false
    }
  },
  methods: {
    ...mapActions(['showSnackbar']),
    async updateDimensionValue ({ activeLayout: layout, index: dimension, value }) {
      if (Number.isNaN(Number(value))) {
        captureException(new Error(`Value passed to updateDimensionValue must be numeric`))
        this.$store.dispatch('showSnackbar', { color: 'error', text: 'Whoops, something went wrong while updating this element' })
      } else {
        this.$emit('updating')
        const payload = { layout, dimension, value, system: this.systemOfMeasurement }
        await this.$axios.put(`/elements/${this.element._id}/value`, payload, {
          params: { versionId: this.version._id }
        })
        this.hasBeenEditedOnce = true
        this.$emit('updated')
        this.$set(this.element.layouts[layout].dimensions[dimension], 'value', +value)
      }
    },
    async updateElementOptions ({ option, value }) {
      this.$emit('updating')
      await this.$axios.put(`/elements/${this.element._id}`, { [option]: value })
      this.$emit('updated')
    },
    async toggleDimensionManualMode ({ index, isManualMode }) {
      const res = await this.$axios.put(`/elements/${this.element._id}/manual-mode`, { dimension: index })
      this.$set(this.element.layouts[this.element.activeLayout].dimensions[index], 'isManualMode', isManualMode)
      if (!isManualMode) {
        // if has been switched back to automatic, update dimension with recalculated value
        this.$set(this.element.layouts[this.element.activeLayout].dimensions[index], 'value', res.data.value)
        this.$emit('updated')
      }
    },
    async deleteElement () {
      this.deleting = true
      try {
        await this.$axios.delete(`/elements/${this.element._id}`)
        this.showSnackbar(`Successfully deleted element "${this.element.name}"`)
        this.$emit('deleted')
        this.deleting = false
      } catch (err) {
        this.showSnackbar({ color: 'error', text: 'Unable to delete element. Please refresh the page and try again.' })
        this.deleting = false
        throw err
      }
    },
    async switchLayout (index) {
      this.switchingLayout = true
      const { data } = await this.$axios.put(`/elements/${this.element._id}/layout`, { index })
      const { quantity, co2 } = data
      this.$set(this.element, 'totalQuantity', quantity)
      this.$set(this.element, 'totalCo2Kg', co2)
      this.$set(this.element, 'activeLayout', index)
      this.switchingLayout = false
      this.$emit('updated')
    }
  }
}
</script>
