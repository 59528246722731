<template>
  <v-card
    class="pa-0 ma-0"
    flat
  >
    <v-container
      fluid
      class="suggestion-card px-0"
    >
      <span
        class="font-weight-light metal--text pl-0 pt-4 pb-4"
        :class="$vuetify.breakpoint.smAndDown ? 'title' : 'headline'"
        style="top:5px"
      >
        <!-- {{ category }} -->
        <span class="text-capitalize">{{ category.type }}{{ category.type.endsWith('s') ? '' : 's' }}</span> -&nbsp;<strong class="text-capitalize">{{ category.name }}</strong>
      </span>
      <v-row
        mt-3
        wrap
      >
        <!-- <v-col
          class="mb-4"
          cols="12"
          sm="auto"
          xl="2"
        >
          <v-img
            class="leaf"
            style="border:2px solid white !important;"
            :class="{ 'mx-auto': $vuetify.breakpoint.smAndDown }"
            height="220"
            width="100%"
            cover
            position="center center"
            :src="imageSrc"
          />
        </v-col> -->
        <HighLevelSummary
          v-if="category.highLevel"
          :headline="category.headline"
          :description="category.description"
        />
        <ElementBreakdown
          v-else
          :category="category"
        />
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import HighLevelSummary from './HighLevelSummary'
import ElementBreakdown from './ElementBreakdown'

export default {
  name: 'CategorySuggestionsCard',
  components: {
    HighLevelSummary,
    ElementBreakdown
  },
  props: {
    category: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imageSrc () {
      let src
      try {
        src = require(`./images/${this.category.name.toLowerCase()}.jpg`)
      } catch (err) {}
      return src
    }
  }
}
</script>
