<template>
  <div
    id="viewDiv"
    class="map"
  />
</template>

<script>

import MapView from '@arcgis/core/views/MapView'
import Map from '@arcgis/core/Map'
import ImageElement from '@arcgis/core/layers/support/ImageElement.js'
import MediaLayer from '@arcgis/core/layers/MediaLayer.js'
import '@arcgis/core/assets/esri/themes/light/main.css'
import Vue from 'vue'
import ExtentAndRotationGeoreference from '@arcgis/core/layers/support/ExtentAndRotationGeoreference'
import { Extent } from '@arcgis/core/geometry'
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer'
import esriConfig from '@arcgis/core/config'
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine.js'
import Layer from '@arcgis/core/layers/Layer'

function createFeatureLayers (src) {
  if (src) {
    return Layer.fromArcGISServerUrl({
      url: src,
      properties: {
        outFields: ['*']
      }
    })
  }
}

// create image elements for each image
function createImageElement (extent, src) {
  const imageElement = new ImageElement({
    image: src,
    georeference: new ExtentAndRotationGeoreference({
      extent: new Extent(extent)
    })
  })
  return imageElement
}

function loadGeoJSONLayer (geoJSON) {
  // create a new blob from geojson feature collection
  const blob = new Blob([JSON.stringify(geoJSON)], {
    type: 'application/json'
  })

  // URL reference to the blob
  const url = URL.createObjectURL(blob)

  const projectRenderer = {
    type: 'simple',
    symbol: {
      type: 'simple-fill',
      color: 'rgba(255,255,255,0.2)'
    } }

  // create new geojson layer using the blob url
  return new GeoJSONLayer({
    url,
    blendMode: 'hard-light',
    renderer: projectRenderer
  })
}

function disableZooming (view) {
  // Removes the zoom action on the popup
  view.popup.actions = []

  // stops propagation of default behavior when an event fires
  function stopEvtPropagation (event) {
    event.stopPropagation()
  }

  // exclude the zoom widget from the default UI
  view.ui.components = ['attribution']

  // disable mouse wheel scroll zooming on the view
  view.on('mouse-wheel', stopEvtPropagation)

  // disable zooming via double-click on the view
  view.on('double-click', stopEvtPropagation)

  // disable zooming out via double-click + Control on the view
  view.on('double-click', ['Control'], stopEvtPropagation)

  // disables pinch-zoom and panning on the view
  view.on('drag', stopEvtPropagation)

  // disable the view's zoom box to prevent the Shift + drag
  // and Shift + Control + drag zoom gestures.
  view.on('drag', ['Shift'], stopEvtPropagation)
  view.on('drag', ['Shift', 'Control'], stopEvtPropagation)

  // prevents zooming with the + and - keys
  view.on('key-down', (event) => {
    const prohibitedKeys = ['+', '-', 'Shift', '_', '=', 'ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft']
    const keyPressed = event.key
    if (prohibitedKeys.indexOf(keyPressed) !== -1) {
      event.stopPropagation()
    }
  })

  return view
}

const createImageLayer = (imageExtent, image, mapOptions) => {
  if (!imageExtent || !image) return
  const imageElement = {
    element: createImageElement(imageExtent, image)
  }

  // MediaLayer - add imageElements
  const mediaLayer = new MediaLayer({
    source: [
      imageElement.element
    ],
    opacity: 0.4,
    title: 'Precipitation',
    ...mapOptions
  })
  return mediaLayer
}

export default Vue.extend({
  name: 'Map',
  props: {
    featureLayerSrc: { type: String, required: false, default: null },
    geoJson: { type: Object, required: true },
    image: { type: String, required: false, default: null },
    imageExtent: { type: Object, required: false, default: null },
    mapOptions: { type: Object, required: false, default: () => ({}) },
    bufferExtentByKm: { type: Number, required: false, default: null }
  },
  async mounted () {
    // TODO: Make this more VUE like.
    esriConfig.apiKey = 'AAPK2761f78aac0c4ed9a3a2a4c668249b52o_oML6JG_VZuRAg-CtuLFg6fHi1zHm00cAm6lrr0ernb4AersRZFCDG2yTSeEUOz'

    const map = new Map({
      basemap: 'arcgis-topographic' // Basemap layer
    })

    // eslint-disable-next-line no-unused-vars
    const view = new MapView({
      container: 'viewDiv',
      map: map,
      constraints: {
        snapToZoom: true,
        rotationEnabled: false
      },
      popup: {
        dockEnabled: true,
        dockOptions: {
          buttonEnabled: false,
          breakpoint: false
        }
      }
    })
    const geoJSONLayer = loadGeoJSONLayer(this.geoJson)

    // the extent of the project will be used as the extent of the map.
    const geoJsonExtent = await geoJSONLayer.queryExtent()
    let bufferedProject
    if (this.bufferExtentByKm) {
      bufferedProject = geometryEngine.geodesicBuffer(geoJsonExtent.extent.clone(), this.bufferExtentByKm, 'kilometers')
    } else {
      bufferedProject = geoJsonExtent.extent.expand(1.5)
    }

    view.set({ extent: bufferedProject.extent })

    const mediaLayer = await createImageLayer(this.imageExtent, this.image, this.mapOptions)
    const featureLayer = await createFeatureLayers(this.extent, this.featureLayerSrc, this.mapOptions)
    view.popup.defaultPopupTemplateEnabled = true

    const layers = []
    mediaLayer && layers.push(mediaLayer)
    this.featureLayerSrc && layers.push(featureLayer)
    layers.push(geoJSONLayer)
    await view.when(disableZooming)
    map.layers.addMany(layers)
  }
})

</script>

<style scoped>
  .map {
    height: 350px;
    width: 100%;
    margin: 0;
    padding: 0;
  }
</style>
