<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, invalid }"
  >
    <v-form
      class="mt-3"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <v-card>
        <v-card-title>{{ element ? 'Update' : 'New' }} custom plant</v-card-title>
        <v-card-text class="pb-0">
          <ValidationProvider
            v-slot="{ errors }"
            name="Name"
            rules="required"
          >
            <v-text-field
              v-model="formData.name"
              data-vv-name="element name"
              :error-messages="errors"
              label="Name"
              placeholder="Choose a name for the custom plant"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Category"
            rules="required"
          >
            <v-select
              v-model="formData.category"
              data-vv-name="category"
              :items="categoriesWithSurvivalFactors"
              item-value="name"
              item-text="name"
              :error-messages="errors"
              label="Category"
              class="category-selected"
              placeholder="Which category does this plant belong in?"
            >
              <template #item="{ item }">
                <!-- <BrandLogo
                  v-if="item.brand"
                  :brand="item.brand"
                  size="35"
                  class="mr-3"
                  style="background: yellow;"
                /> -->
                <span class="category-dropdown-item">{{ item.name }}</span>
              </template>
            </v-select>
          </ValidationProvider>
          <div :class="{['hidden']: !!element}">
            <!-- <span class="caption">Foliage type</span> -->
            <!-- <v-radio-group
              v-model="formData.foliageType"
              row
              class="mt-1"
            >
              <v-radio
                value="deciduous"
                label="Deciduous"
                color="shamrock"
              />
              <v-radio
                value="evergreen"
                label="Evergreen"
                color="shamrock"
              />
            </v-radio-group> -->
            <ValidationProvider
              v-slot="{ errors }"
              name="Sequestration Rate"
              rules="required|min_value:0"
            >
              <v-text-field
                v-model="formData.sequestrationRate"
                :error-messages="errors"
                :label="isTotalSequestration ? 'Total sequestration over 50-year project lifespan' : `${formData.category === 'other' ? 'S' : 'Annual s'}equestration rate`"
                :hint="isTotalSequestration ? `How much will this ${descriptor} sequester over 50 years?` : `How much CO₂e does this ${descriptor} sequester per year ${formData.category !== 'other' ? 'when mature' : ''}?`"
                :suffix="isTotalSequestration ? 'tonnes CO₂e' : `tonnes CO₂e${formData.category === 'other' ? '/m2' : ''}/year`"
                class="mb-4"
              />
            </ValidationProvider>
            <a
              class="d-block metal--text mb-5"
              style="text-decoration: underline; width: fit-content; user-select: none;"
              @click="isTotalSequestration = !isTotalSequestration"
            >
              {{ isTotalSequestration ? 'Switch back to annual sequestration' : 'Click here to enter a total sequestration value instead' }}
            </a>
            <p
              v-if="formData.category !== 'other'"
              class="shamrock--text"
            >
              <v-icon
                color="shamrock"
                class="mr-2"
                style="font-size: 21px;"
              >
                mdi-information-outline
              </v-icon>
              <span>Replanting is automatically accounted for. Decomposition is not, so should be factored into the provided sequestration value.</span>
            </p>
          </div>
          <div
            v-if="element"
            class="ma-4 mb-0"
            style="text-align:center"
          >
            <b>Edits made to existing custom elements will be reflected across all project instances.</b>
          </div>
        </v-card-text>
        <v-card-actions class="pb-4 px-5">
          <v-btn
            color="red"
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="shamrock white--text"
            elevation="0"
            type="submit"
          >
            {{ element ? 'Update' : 'Create' }} element
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import survivalFactorValues from './survivalFactorValues'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'

const defaultFormData = (element) => ({
  _id: element?._id,
  name: element?.name || '',
  category: element?.category || null,
  sequestrationRate: 0,
  foliageType: 'deciduous'
})

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    element: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      dictionary: {
        fields: {
          'Sequestration Rate': {
            min_value: 'Sequestration rate must be more than zero'
          },
          density: {
            min_value: 'Density must be more than zero'
          }
        }
      },
      formData: defaultFormData(this.element),
      isTotalSequestration: false
    }
  },
  computed: {
    descriptor () {
      return this.formData.category === 'other' ? 'plant' : this.formData.category
    },
    decompositionMultiplier () {
      // in the spreadsheet, all decidious and all evergreen trees have the same relationship between sequestration and decomposition values
      // therefore we can use the foliage type to determine mature decomposition values from the given mature sequestration value
      return this.formData.foliageType === 'deciduous' ? -20.73 : -46.36
    },
    categoriesWithSurvivalFactors () {
      const keys = Object.keys(survivalFactorValues)
      return this.categories.filter((category) =>
        keys.includes(category.name.toLowerCase()))
    }
  },
  created () {
    localize({ en: this.dictionary })
  },
  methods: {
    async onSubmit () {
      let matureSequestrationRate = +this.formData.sequestrationRate
      if (this.isTotalSequestration) {
        const lifespan = 50
        matureSequestrationRate = this.formData.sequestrationRate / lifespan
      }
      const { category, name, _id } = this.formData
      if (_id) {
        let element = {
          _id,
          type: 'plant',
          name,
          category
        }
        this.$emit('update-element', element)
      } else {
        let element = {
          type: 'plant',
          name,
          category
        }
        if (category === 'other') {
          element = {
            ...element,
            co2MeasuredPer: 'm2',
            formulaForGivenYear: `$qty * $yrs * ${matureSequestrationRate * -1}`, // multiply by minus 1 for emissions
            layouts: [{
              formula: '$a',
              dimensions: [{
                units: {
                  metric: { symbol: 'm2', toSi: 1 },
                  imperial: { symbol: 'sf', toSi: 0.092903 }
                },
                label: 'Area',
                type: 'area',
                ref: '$a',
                editable: true
              }]
            }]
          }
        } else {
          const parseSurvivalValuesIntoFactorData = (values) => {
            let year = 0
            const ret = []
            for (const value of values) {
              ret.push({
                yearRange: [year, year + 5],
                value
              })
              year += 5
            }
            return ret
          }
          element = {
            ...element,
            co2MeasuredPer: 'unit',
            sinkFormula: {
              growthZones: survivalFactorValues[category?.toLowerCase()].map(({
                growthZone,
                sequestration,
                decomposition,
                replanting
              }) => ({
                name: growthZone,
                matureRates: {
                  sequestration: matureSequestrationRate,
                  // decomposition: matureSequestrationRate * this.decompositionMultiplier // calculated based on foliage type
                  decomposition: 0 // users cannot input and currently no calculation for this, so left as zero
                },
                survivalFactors: {
                  sequestration: parseSurvivalValuesIntoFactorData(sequestration),
                  decomposition: parseSurvivalValuesIntoFactorData(decomposition)
                },
                replantingFactors: parseSurvivalValuesIntoFactorData(replanting)
              }))
            },
            layouts: [{
              dimensions: [{
                units: {
                  metric: { symbol: 'quantity', toSi: 1 },
                  imperial: { symbol: 'quantity', toSi: 1 }
                },
                valueRange: [0, 100],
                label: 'Quantity',
                type: 'count',
                ref: '$q',
                editable: true
              }],
              formula: '$q'
            }]
          }
        }
        // remove observers
        element = JSON.parse(JSON.stringify(element))
        this.$emit('create-element', element)
      }
      this.formData = { ...JSON.parse(JSON.stringify(defaultFormData())) }
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
.category-selected * {
  text-transform: capitalize;
}
.category-dropdown, .category-dropdown-item {
  text-transform: capitalize;
}
</style>
