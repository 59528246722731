var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.readonly || _vm.readonly && _vm.innerValue.active)?_c('tr',{staticClass:"ma-0 pa-0"},[_c('td',{staticClass:"row-name"},[_c('v-checkbox',{staticClass:"mt-0 mr-5",attrs:{"id":"active","readonly":_vm.readonly,"flat":"","label":_vm.vModel.name,"hide-details":""},model:{value:(_vm.innerValue.active),callback:function ($$v) {_vm.$set(_vm.innerValue, "active", $$v)},expression:"innerValue.active"}})],1),(_vm.showQuantity)?_c('td',[_c('ValidationProvider',{attrs:{"name":_vm.fieldName('Quantity'),"rules":_vm.quantityRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"id":"quantity","disabled":!_vm.innerValue.active,"reverse":"","type":"number","error-messages":errors,"hide-details":errors.length === 0,"hide-spin-buttons":""},model:{value:(_vm.innerValue.quantity),callback:function ($$v) {_vm.$set(_vm.innerValue, "quantity", $$v)},expression:"innerValue.quantity"}})]}}],null,false,728765813)})],1):_vm._e(),(!_vm.showQuantity)?_c('td',[_c('ValidationProvider',{attrs:{"name":_vm.fieldName('Area'),"rules":_vm.areaRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AreaOrPercentOfInput',{staticClass:"mt-0 pt-0",attrs:{"max":_vm.availableArea,"disabled":!_vm.innerValue.active,"type":_vm.enterSiteInformationBy,"reverse":"","name":"area","prefix":_vm.areaUnits,"hide-spin-buttons":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.innerValue.area),callback:function ($$v) {_vm.$set(_vm.innerValue, "area", _vm._n($$v))},expression:"innerValue.area"}})]}}],null,false,3165096599)})],1):_vm._e(),(_vm.showCondition)?_c('td',[_c('ValidationProvider',{attrs:{"name":_vm.fieldName('Condition'),"rules":_vm.conditionRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0 no-wrap",attrs:{"disabled":!_vm.innerValue.active,"row":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.innerValue.condition),callback:function ($$v) {_vm.$set(_vm.innerValue, "condition", $$v)},expression:"innerValue.condition"}},[_c('v-spacer'),_c('v-radio',{attrs:{"label":"G","value":"good"}}),_c('v-radio',{attrs:{"label":"M","value":"moderate"}}),_c('v-radio',{attrs:{"label":"P","value":"poor"}})],1)]}}],null,false,1595585772)})],1):_vm._e(),(_vm.showPercentNative)?_c('td',[_c('ValidationProvider',{attrs:{"name":_vm.fieldName('Percent Native'),"rules":_vm.percentageRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"hide-spin-buttons":"","disabled":!_vm.innerValue.active,"reverse":"","prefix":"%","type":"number","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.innerValue.percentNative),callback:function ($$v) {_vm.$set(_vm.innerValue, "percentNative", $$v)},expression:"innerValue.percentNative"}})]}}],null,false,707877079)})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }