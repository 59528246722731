<template>
  <v-container
    class="pt-7"
    :class="$vuetify.breakpoint.xsOnly ? 'px-5' : 'px-8'"
    :style="{ overflow: visible ? 'auto' : 'hidden' }"
  >
    <v-row>
      <v-col>
        <p
          class="font-weight-light shamrock--text"
          :class="$vuetify.breakpoint.xsOnly ? ['headline', 'mb-2'] : ($vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2')"
        >
          Improve your project’s carbon impact
        </p>

        <p
          class="font-weight-light metal--text"
          :class="$vuetify.breakpoint.xsOnly ? ['title', 'mb-2'] : ($vuetify.breakpoint.smAndDown ? 'headline' : 'display-1')"
        >
          <span v-if="categories.length === 0">
            Add further detail to your project to get started.
          </span>
          <span v-else>
            Consider these alternatives:
          </span>
        </p>
        <div style="margin: 0 -10px;">
          <HighLevelSummary
            headline="Add more trees, wetlands or shrubs"
            description="Add carbon sequestering biomass to move your project in the direction of being climate positive."
          />
        </div>
        <CategorySuggestionsCard
          v-for="(category, index) of suggestionsCategories"
          :key="index"
          :category="category"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HighLevelSummary from './HighLevelSummary'
import CategorySuggestionsCard from './CategorySuggestionsCard'

export default {
  name: 'DesignSuggestions',
  components: {
    HighLevelSummary,
    CategorySuggestionsCard
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    suggestionsCategories () {
      return this.categories.map(x => x.suggestionsCategories).flat()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
