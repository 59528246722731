<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr class="condition-row">
          <th>
            <v-checkbox
              v-model="readonly"
              :label="sectionName"
              class="edit-checkbox"
              color="blue"
              :on-icon="'mdi-pencil-outline'"
              :off-icon="'mdi-pencil-off-outline'"
            />
          </th>
          <th
            v-if="!showQuantity"
            class="text-capitalize"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <div
                  class="row-handle align-center"
                  v-on="on"
                >
                  <v-icon
                    color="blue"
                    size="medium"
                  >
                    mdi-information-variant-circle-outline
                  </v-icon>
                  {{ enterSiteInformationBy }}
                </div>
              </template>
              <div>
                <span v-if="enterSiteInformationBy === 'area'">
                  Sum of all planted areas plus hardscape and building area must equal overall site area.
                </span>
                <span v-else>
                  Percentage of all planted areas must equal 100%.
                </span>
              </div>
            </v-tooltip>
          </th>
          <th
            v-if="showQuantity"
            class="text-capitalize"
          >
            Quantity
          </th>
          <th
            v-if="showPercentNative"
            class="text-capitalize"
          >
            % Native
          </th>
          <th
            v-if="showCondition"
            class="text-capitalize"
          >
            Condition
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="row in vModel">
          <SiteConditionRow
            :key="row.cpid"
            :v-model="row"
            :available-area="availableArea"
            :readonly="readonly"
            :show-quantity="showQuantity"
            :show-percent-native="showPercentNative"
            :show-condition="showCondition"
            :rules="rules"
            :enter-site-information-by="enterSiteInformationBy"
            @input="input"
          />
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import SiteConditionRow from './SiteConditionRow'

export default {
  name: 'SiteConditionSection',
  components: { SiteConditionRow },
  props: {
    availableArea: { type: Number, required: false, default: null },
    sectionName: { type: String, required: true },
    showPercentNative: { type: Boolean, default: false },
    showCondition: { type: Boolean, default: false },
    showQuantity: { type: Boolean, default: false },
    vModel: { type: Array, default: () => [] },
    enterSiteInformationBy: { type: String, default: '' },
    rules: { type: Object, default: () => {} }
  },
  data () {
    return {
      internalValue: {},
      readonly: true
    }
  },
  methods: {
    input (value) {
      this.internalValue = value
      this.$emit('input', value)
    }
  }
}
</script>
<style>
tr.condition-row > th:nth-child(2){
  width: 150px;
}
tr.condition-row > th:nth-child(3){
  width: 230px;
}
.edit-checkbox .v-label {
  font-size: 15px;
}
tr.condition-row th {
  font-size: 15px !important;
}

</style>
