<template>
  <div class="white pl-2 pr-2">
    <v-expansion-panels
      v-model="panels"
      flat
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-2">
          <CategoryHeader>
            Evapotranspiration at Project Site
          </CategoryHeader>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ValueWithDataBook
            :highlighted="true"
          >
            <template v-slot:variable-name>
              Average Annual Potential Evapotranspiration
            </template>
            <template v-slot:variable-value>
              <SmartValue
                :value="evapotranspiration"
                :from-unit="'mm'"
                :to-imperial="'in'"
                :format="'0,0'"
                :to-metric="'cm'"
              />
            </template>
          </ValueWithDataBook>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-2">
          <CategoryHeader>
            Landscape Water use
          </CategoryHeader>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ValueWithDataBook
            :highlighted="false"
          >
            <template v-slot:variable-name>
              Total Planted Area
            </template>
            <template v-slot:variable-value>
              <SmartValue
                :value="waterUse.totalPlantedArea"
                :from-unit="'m2'"
                :to-imperial="'ft2'"
                :format="'0,0'"
                :to-metric="'m2'"
              />
            </template>
          </ValueWithDataBook>
          <ValueWithDataBook
            :highlighted="false"
          >
            <template v-slot:variable-name>
              Annual Baseline
            </template>
            <template v-slot:variable-value>
              <SmartValue
                :value="waterUse.annualBaseline"
                :from-unit="'l'"
                :to-imperial="'gal'"
                :to-metric="'l'"
                :format="'0,0'"
                :append="'/yr'"
              />
            </template>
          </ValueWithDataBook>
          <ValueWithDataBook>
            <template v-slot:variable-name>
              Maximum Water Allowance
            </template>
            <template v-slot:variable-value>
              <SmartValue
                :value="waterUse.maximumWaterAllowance"
                :from-unit="'l'"
                :to-imperial="'gal'"
                :to-metric="'l'"
                :format="'0,0'"
                :append="'/yr'"
              />
            </template>
          </ValueWithDataBook>
          <ValueWithDataBook
            :color="colors.cpdblue"
          >
            <template v-slot:variable-name>
              Annual Site Water Usage
            </template>
            <template v-slot:variable-value>
              <SmartValue
                :value="waterUse.annualSiteWaterUse"
                :from-unit="'l'"
                :to-imperial="'gal'"
                :to-metric="'l'"
                :format="'0,0'"
                :append="'/yr'"
                :color="'colors.cpdblue'"
              />
            </template>
          </ValueWithDataBook>
          <v-row class="mt-8 mb-4">
            <v-col
              cols="6"
              class="text-center pb-8"
            >
              <WaterReductionFromBaseline
                :height="'125px'"
                :reduction="waterUse.reductionFromBaseline"
              />
              Reduction From Baseline
            </v-col>
            <v-col
              v-if="waterUse.reductionFromBaseline === NaN || waterUse.reductionFromBaseline === undefined"
              class="text-center middle-align pa-12"
              cols="6"
            >
              No Data
            </v-col>
            <v-col
              v-if="waterUse.reductionFromBaseline >= 30"
              class="text-center middle-align pa-12"
              :style="`color: ${colors.cpdblue}`"
              cols="6"
            >
              Congratulations! You've exceeded the minimum 30% water use reduction.
            </v-col>
            <v-col
              v-if="waterUse.reductionFromBaseline < 30"
              class="text-center middle-align pa-12"
              cols="6"
            >
              Reduce water use by at least 30% from the baseline for a water saving site.
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import CategoryHeader from '@/components/atoms/CategoryHeader.vue'
import { convertProjectSystem } from '@/helpers/unitConversions'
import SmartValue from '@/components/atoms/SmartValue.vue'
import colors from '../../../../schema/colors'

import Vue from 'vue'
import ValueWithDataBook from '@/components/molecules/ValueWithDataBook.vue'
import WaterReductionFromBaseline from '@/components/charts/WaterReductionFromBaseline.vue'

const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')
const { mapGetters: mapVersionGetters } = createNamespacedHelpers('projects/versions')

export default Vue.extend({
  components: {
    ValueWithDataBook,
    WaterReductionFromBaseline,
    CategoryHeader,
    SmartValue
  },
  data () {
    return {
      geoJSON: null,
      waterSources: [{ _id: 1, name: 'Greywater' }],
      waterSource: null,
      panels: [0],
      colors: colors
    }
  },
  computed: {
    ...mapProjectGetters(['project', 'evapotranspiration']),
    ...mapVersionGetters(['version', 'statsLoading', 'waterUse']),
    totalLandscapedArea: function () {
      return convertProjectSystem(
        {
          v: this['version'].attributes['plantedArea'],
          project: this['project'],
          from: 'm2',
          toMetric: 'm2',
          toImperial: 'ft2'
        }
      )?.value
    },
    percentReductionFromBaseline: function () {
      return 50
    },
    showCongratulations: function () {
      return this.reductionFromBaseline > 30
    },
    image: function () {
      return this['project'].enrichedData.precipitationImage?.parsedResponse.base64
    },
    imageExtent: function () {
      return this['project'].enrichedData.precipitationImage?.query.extent
    }
  },
  async created () {
    const { data } = await this.$axios.get(`/projects/${this['project']._id}/location`)
    this.geoJSON = data
  }
})
</script>

<style lang="scss">
.data-label-section {
  margin-bottom: 20px;
  padding: 5px 13px;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
  background: #f5f6f5;
}
.stats-panel .v-expansion-panel-content__wrap {
  padding: 0;
}
.new-element-form {
  position: relative;
  --box-shadow-color: var(--v-shamrock-base);
}
</style>
