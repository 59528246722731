<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, invalid }"
  >
    <v-form
      class="mt-3"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <v-card>
        <v-card-title>{{ element ? 'Update' : 'New' }} custom operation</v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            name="Element Name"
            rules="required"
          >
            <v-text-field
              v-model="formData.name"
              :error-messages="errors"
              label="Name"
              placeholder="Choose a name for the custom operation"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Category"
            rules="required"
          >
            <v-select
              v-model="formData.category"
              :error-messages="errors"
              class="category-selected"
              label="Category"
              placeholder="Select a category"
              :items="categories"
              item-value="name"
              item-text="name"
            >
              <template #item="{ item }">
                <!-- <BrandLogo
                  v-if="item.brand"
                  :brand="item.brand"
                  size="35"
                  class="mr-3"
                  style="background: yellow;"
                /> -->
                <span class="category-dropdown-item">{{ item.name }}</span>
              </template>
            </v-select>
          </ValidationProvider>
          <div :class="{['hidden']: !!element}">
            <ValidationProvider
              v-slot="{ errors }"
              name="Impact type:"
              rules="required"
            >
              <v-radio-group
                v-model="formData.impactType"
                data-vv-name="impact type"
                :error-messages="errors"
                row
              >
                <template #label>
                  <span
                    style="font-family: 'Roboto', sans-serif;"
                    class="font-weight-medium"
                  >Impact type:</span>
                </template>
                <v-radio
                  :value="0"
                  label="One time impact"
                  color="shamrock"
                />
                <v-radio
                  :value="1"
                  label="Yearly impact"
                  color="shamrock"
                />
              </v-radio-group>
            </ValidationProvider>
            <div class="title">
              Impact data
            </div>
            <v-tabs v-model="activeTab">
              <v-tab>Simple mode</v-tab>
              <v-tab>Detail mode</v-tab>
              <v-tabs-items v-model="activeTab">
                <v-tab-item>
                  <div class="subtitle-1 mt-3 mb-2">
                    How is the operation measured?
                  </div>
                  <v-row class="d-flex">
                    <v-col class="pt-0">
                      <v-text-field
                        ref="label"
                        v-model="newDimension.label"
                        label="Label"
                        placeholder="eg. Power"
                        :error-messages="newDimensionErrors.label"
                        hint="Give the dimension a descriptor eg. Power, Area, Hours used annually"
                        @input="validateDimension"
                      />
                    </v-col>
                    <v-col class="pt-0">
                      <v-text-field
                        v-model="newDimension.units"
                        :error-messages="newDimensionErrors.units"
                        label="Units"
                        placeholder="eg. watts"
                        hint="What units does the dimension use eg. watts, sf, hours.."
                        :counter="8"
                        @input="validateDimension"
                      />
                    </v-col>
                  </v-row>
                  <v-text-field
                    v-model="formData.co2PerUnit"
                    :label="`${formData.impactType === 1 ? 'Annual i' : 'I'}mpact per unit`"
                    type="number"
                    :suffix="`kgCO₂e per ${formData.dimensions[0] ? removeLastS(formData.dimensions[0].units) : 'unit'} ${formData.impactType === 1 ? ' per year' : ''}`"
                    :hint="`How many kg of co2 are emitted per ${formData.dimensions[0] ? removeLastS(formData.dimensions[0].units) : 'unit'} ${formData.impactType === 1 ? 'per year' : ''}`"
                  />
                </v-tab-item>
                <v-tab-item>
                  <div class="subtitle-1 mt-3">
                    How is the operation measured?
                  </div>
                  <div class="subtitle-2 mt-3">
                    {{ formData.dimensions.length ? 'Add your dimensions below' : 'Use the form to add your first dimension' }}
                  </div>
                  <v-form
                    @submit.prevent="addDimension"
                  >
                    <v-row class="d-flex">
                      <v-col class="pt-0">
                        <v-text-field
                          ref="label"
                          v-model="newDimension.label"
                          label="Label"
                          hint="Give the dimension a descriptor eg. Area, Power, Hours used annually.."
                          :error-messages="newDimensionErrors.label"
                          @input="validateDimension"
                        />
                      </v-col>
                      <v-col class="pt-0">
                        <v-text-field
                          v-model="newDimension.units"
                          :error-messages="newDimensionErrors.units"
                          label="Units"
                          hint="What units does the dimension use eg. ft2, watts, hours.."
                          :counter="8"
                          @input="validateDimension"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        class="pt-0"
                      >
                        <v-btn
                          type="submit"
                          class="mt-2"
                          outlined
                          color="shamrock"
                          :disabled="!newDimension.label || !newDimension.units || Boolean(newDimensionErrors.label) || Boolean(newDimensionErrors.units)"
                        >
                          Add dimension
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                  <div
                    v-if="formData.dimensions.length"
                    class="d-flex align-center mb-5"
                  >
                    <span class="mr-1">Dimensions:</span>
                    <v-chip
                      v-for="(dimension, index) in formData.dimensions"
                      :key="index"
                      class="mx-1"
                      close
                      @click="() => addDimensionToFormula(dimension)"
                      @click:close="() => deleteDimension(index, dimension)"
                    >
                      {{ dimension.label }} ({{ dimension.units }})
                    </v-chip>
                  </div>
                  <div v-if="formData.dimensions.length > 0">
                    <div class="subtitle-1">
                      Formula
                    </div>
                    <v-text-field
                      v-model="formData.formula"
                      :label="`How many kilograms of carbon will this operation emit${formData.impactType === 1 ? ' each year' : ''}?`"
                      :prefix="`${formData.impactType === 1 ? 'Annual impact = ' : 'Impact = '}`"
                      suffix="kgCO₂e"
                      placeholder=" "
                      :hint="formData.formula.length ? 'Select from the dimensions above to use them in your formula' : 'Use mathematical operators + - * / ( ) to create your formula'"
                      :error-messages="formulaError"
                      @input="validateFormula"
                    />
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </div>
          <div
            v-if="element"
            class="ma-4 mb-0"
            style="text-align:center"
          >
            <b>Edits made to existing custom elements will be reflected across all project instances.</b>
          </div>
        </v-card-text>
        <v-card-actions class="py-4 px-5">
          <v-btn
            color="red"
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="shamrock white--text"
            elevation="0"
            type="submit"
          >
            {{ element ? 'Update' : 'Create' }} element
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import getUniqueRef from '@/helpers/getUniqueRef'
import { evaluate } from 'mathjs'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const defaultFormData = (element) => ({
  _id: element?._id,
  name: element?.name || '',
  category: element?.category || null,
  impactType: 0,
  dimensions: [],
  formula: element?.name || '',
  co2PerUnit: element?.name || 0
})

export default {
  name: 'CustomOperationForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    category: {
      type: String,
      default: null
    },
    categories: {
      type: Array,
      default: () => []
    },
    element: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      activeTab: 0,
      newDimension: {
        label: '',
        units: ''
      },
      newDimensionErrors: {},
      formData: JSON.parse(JSON.stringify(defaultFormData(this.element))),
      formulaError: null
    }
  },
  computed: {
    isDetailModel () {
      return this.activeTab === 1
    }
  },
  watch: {
    category: {
      immediate: true,
      handler (val) {
        this.$set(this.formData, 'category', val)
      }
    },
    newDimension: {
      deep: true,
      handler (val) {
        if (!this.isDetailModel) {
          this.formData.dimensions = [val]
          this.formData.formula = `${this.formData.dimensions[0].units} * ${this.formData.co2PerUnit}`
        }
      }
    },
    'formData.co2PerUnit': function () {
      if (this.formData.dimensions[0]) {
        this.formData.formula = `${this.formData.dimensions[0].units} * ${this.formData.co2PerUnit}`
      }
    }
  },
  methods: {

    validateDimension () {
      this.newDimensionErrors = {}
      if (this.isDetailModel && this.formData.dimensions.some(dim => dim.label === this.newDimension.label)) {
        this.newDimensionErrors.label = 'There is already a dimension with that label'
      }
      if (this.newDimension.units.includes(' ')) {
        this.newDimensionErrors.units = 'Units cannot include spaces'
      }
      if (this.newDimension.units.length && this.newDimension.units === this.newDimension.label) {
        this.newDimensionErrors.units = 'Units cannot be the same as label'
      }
      if (this.newDimension.units.length > 8) {
        this.newDimensionErrors.units = 'Units must be 8 or less characters'
      }
    },
    addDimension () {
      this.formData.dimensions.push(this.newDimension)
      this.newDimension = { label: '', units: '' }
      this.$refs.label.focus()
    },
    deleteDimension (index, dimension) {
      this.$delete(this.formData.dimensions, index)
      const regex = new RegExp(dimension.units, 'g')
      this.formData.formula = this.formData.formula.replace(regex, '')
    },
    addDimensionToFormula (dimension) {
      this.formData.formula += `${dimension.units}`
      this.validateFormula()
    },
    validateFormula () {
      const vars = [...this.formData.dimensions.map(x => x.units)]
      const scope = Object.fromEntries(vars.map(x => [x, 1]))
      this.formulaError = null
      for (const x of vars) {
        if (!this.formData.formula.includes(x)) {
          this.formulaError = `Your formula must use all dimensions - delete "${x}" or add it to the formula.`
        }
      }
      try {
        evaluate(this.formData.formula, scope)
      } catch (err) {
        this.formulaError = 'Invalid formula - ' + err.toString().split(':')[1] || err
      }
    },
    async onSubmit () {
      if (!this.formulaError && this.formData.formula.length) {
        const { _id, name, category, dimensions, impactType } = this.formData
        if (!_id) {
          let { formula } = this.formData
          let element = {
            name,
            category,
            type: 'maintenance'
          }
          if (impactType === 0) {
            element.formulaForOneTime = '$qty'
          } else {
            element.formulaForGivenYear = '$qty * $yrs'
          }
          const refs = []
          for (const dimension of dimensions) {
            // replace unit descriptor with unique ref
            const ref = getUniqueRef(dimension.label, refs)
            dimension.ref = ref
            const regex = new RegExp(dimension.units, 'g')
            formula = formula.replace(regex, ref)
            refs.push(ref)
            // parse dimensions into expected format
            dimension.units = {
              metric: { symbol: dimension.units, toSi: 1 },
              imperial: { symbol: dimension.units, toSi: 1 }
            }
            dimension.valueRange = [0, 100]
            dimension.editable = true
          }
          element.layouts = [{
            dimensions,
            formula
          }]
          // remove observers
          element = JSON.parse(JSON.stringify(element))
          this.$emit('create-element', element)
          this.formData = { ...JSON.parse(JSON.stringify(defaultFormData)) }
        } else {
          let element = {
            _id,
            name,
            category,
            type: 'maintenance'
          }
          this.$emit('update-element', element)
        }
      }
    },
    removeLastS (str) {
      if (!str) return
      if (str.substr(str.length - 1) === 's') {
        str = str.substr(0, str.length - 1)
      }
      return str
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
.category-selected * {
  text-transform: capitalize;
}
.category-dropdown, .category-dropdown-item {
  text-transform: capitalize;
}
</style>
