<template>
  <tr
    v-if="!readonly || readonly && innerValue.active"
    class="ma-0 pa-0"
  >
    <td class="row-name">
      <v-checkbox
        id="active"
        v-model="innerValue.active"
        :readonly="readonly"
        flat
        :label="vModel.name"
        class="mt-0 mr-5"
        hide-details
      />
    </td>
    <td v-if="showQuantity">
      <ValidationProvider
        v-slot="{ errors }"
        :name="fieldName('Quantity')"
        :rules="quantityRules"
      >
        <v-text-field
          id="quantity"
          v-model="innerValue.quantity"
          :disabled="!innerValue.active"
          reverse
          type="number"
          :error-messages="errors"
          :hide-details="errors.length === 0"
          hide-spin-buttons
          class="mt-0 pt-0"
        />
      </ValidationProvider>
    </td>
    <td v-if="!showQuantity">
      <ValidationProvider
        v-slot="{ errors }"
        :name="fieldName('Area')"
        :rules="areaRules"
      >
        <AreaOrPercentOfInput
          v-model.number="innerValue.area"
          :max="availableArea"
          :disabled="!innerValue.active"
          :type="enterSiteInformationBy"
          reverse
          name="area"
          :prefix="areaUnits"
          hide-spin-buttons
          :error-messages="errors"
          :hide-details="errors.length === 0"
          class="mt-0 pt-0"
        />
      </ValidationProvider>
    </td>
    <td v-if="showCondition">
      <ValidationProvider
        v-slot="{ errors }"
        :name="fieldName('Condition')"
        :rules="conditionRules"
      >
        <v-radio-group
          v-model="innerValue.condition"
          :disabled="!innerValue.active"
          row
          :error-messages="errors"
          :hide-details="errors.length === 0"
          class="mt-0 no-wrap"
        >
          <v-spacer />
          <v-radio
            label="G"
            value="good"
          />
          <v-radio
            label="M"
            value="moderate"
          />
          <v-radio
            label="P"
            value="poor"
          />
        </v-radio-group>
      </ValidationProvider>
    </td>
    <td v-if="showPercentNative">
      <ValidationProvider
        v-slot="{ errors }"
        :name="fieldName('Percent Native')"
        :rules="percentageRules"
      >
        <v-text-field
          v-model="innerValue.percentNative"
          hide-spin-buttons
          :disabled="!innerValue.active"
          reverse
          prefix="%"
          type="number"
          :error-messages="errors"
          :hide-details="errors.length === 0"
          class="mt-0 pt-0"
        />
      </ValidationProvider>
    </td>
  </tr>
</template>

<script>
import unitTools from '../../../mixins/unitTools'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, localize } from 'vee-validate'
import AreaOrPercentOfInput from '@/components/form/AreaOrPercentOfInput'
const { mapGetters } = createNamespacedHelpers('projects')

export default {
  name: 'EcosystemRow',
  components: { ValidationProvider, AreaOrPercentOfInput },
  mixins: [unitTools],
  props: {
    vModel: { type: Object, required: true },
    availableArea: { type: Number, required: false, default: null },
    readonly: { type: Boolean, default: true },
    showCondition: { type: Boolean, default: false },
    showPercentNative: { type: Boolean, default: false },
    showQuantity: { type: Boolean, default: false },
    enterSiteInformationBy: { type: String, default: '' },
    rules: { type: Object, default: () => {} }
  },
  data () {
    return {
      innerValue: {
        name: this.vModel.name,
        active: this.vModel.active,
        area: this.vModel.area,
        quantity: this.vModel.quantity,
        condition: this.vModel.condition,
        cpdId: this.vModel.cpdId,
        percentNative: this.vModel.percentNative
      }
    }
  },
  computed: {
    ...mapGetters(['project']),
    percentageRules () {
      return { required: !!this.innerValue.active && !!this.innerValue.name, between: [0, 100] }
    },
    areaRules () {
      return { required: !!this.innerValue.active && !!this.innerValue.name, ...this.rules?.area || {} }
    },
    conditionRules () {
      return { required: !!this.innerValue.active && !!this.innerValue.name, ...this.rules?.condition || {} }
    },
    quantityRules () {
      return { required: !!this.innerValue.active && !!this.innerValue.name, min_value: 0, ...this.rules?.quantity || {} }
    }
  },
  watch: {
    innerValue: {
      handler: function (val) {
        this.$emit('input', val)
      },
      deep: true
    }
  },
  created () {
    const dictionary = {
      fields: {
        [this.fieldName('Area')]: {
          sum_to_large: 'Total planted area is larger than available area',
          sum_to_small: 'Total planted area is smaller than available area'
        }
      }
    }
    localize('en', dictionary)
  },
  methods: {
    fieldName (fieldName) {
      return this.innerValue.name + ' ' + fieldName
    }
  }
}
</script>
<style>
.v-text-field__prefix {
  font-size: 10px !important;
}
.row-name .v-input--is-readonly .v-input--selection-controls__input {
  visibility: hidden !important;
}
</style>
