<template>
  <v-expansion-panels
    v-if="$vuetify.breakpoint.smAndUp"
    flat
    mandatory
    multiple
    :value="expansionPanels"
  >
    <v-expansion-panel class="stats-panel">
      <v-expansion-panel-header class="px-4 py-2">
        <span class="title d-flex align-center">
          <v-icon class="mr-3">mdi-poll</v-icon>
          Version Stats
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="px-5">
        <CalculatorStats
          :version-id="version._id"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <BenefitsExpansionPanel
      v-if="type === 'benefit'"
      :version-id="version._id"
      :project-id="project._id"
      @set-subtype="(value) => $emit('set-subtype', value)"
    />
    <template v-else>
      <v-expansion-panel
        v-if="canShowCPDVersion(2)"
        class="new-element-form"
      >
        <v-expansion-panel-header class="px-4 py-2">
          <span class="title d-flex align-center">
            <v-icon class="mr-3">{{ typeIcon }}</v-icon>
            New {{ type }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-intro="'Get started by adding your first material.'"
          v-intro-step="3"
          v-intro-position="'right'"
        >
          <NewElementForm
            :type="type"
            :adding-elements="addingElements"
            :cpd-version="2"
            :event-key="eventKey"
            @delete-element="(element, callback) => $emit( 'delete-element', element, callback)"
            @add-elements="(elements, related, callback) => $emit('add-elements', elements, related, callback)"
            @update-element="(element, related, callback) => $emit('update-element', element, related, callback)"
            @intersect="$emit('play-intro')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="canShowCPDVersion(3)"
        class="new-element-form"
      >
        <v-expansion-panel-header class="px-4 py-2">
          <span class="title d-flex align-center">
            <v-icon class="mr-3">{{ typeIcon }}</v-icon>
            New V3 {{ type }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-intro="'Get started by adding your first material.'"
          v-intro-step="3"
          v-intro-position="'right'"
        >
          <NewElementForm
            :type="type"
            :adding-elements="addingElements"
            :cpd-version="3"
            :event-key="eventKey"
            @delete-element="(element, callback) => $emit( 'delete-element', element, callback)"
            @update-element="(element, related, callback) => $emit('update-element', element, related, callback)"
            @add-elements="(elements, related, callback) => $emit('add-elements', elements, related, callback)"
            @intersect="$emit('play-intro')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>
  </v-expansion-panels>
  <v-row
    v-else
    class="d-flex justify-space-between"
    no-gutters
  >
    <v-dialog
      v-model="modalOpen"
    >
      <template #activator="{ on }">
        <v-col cols="4">
          <v-btn
            color="white shamrock--text"
            elevation="0"
            rounded
            block
            v-on="on"
            @click="modalTab = 0"
          >
            <v-icon
              class="mr-3"
              left
            >
              mdi-poll
            </v-icon>
            Stats
          </v-btn>
        </v-col>
        <v-col
          cols="8"
          class="pl-3"
        >
          <v-btn
            color="success white--text"
            elevation="0"
            rounded
            block
            v-on="on"
            @click="modalTab = 1"
          >
            <v-icon
              class="mr-3"
              left
            >
              {{ typeIcon }}
            </v-icon>
            New {{ type }}
          </v-btn>
        </v-col>
      </template>
      <v-card>
        <v-card-title class="px-4">
          <div>
            <v-icon class="mr-3">
              {{ modalTab === 0 ? 'mdi-poll' : typeIcon }}
            </v-icon>
            {{ modalTab === 0 ? 'Version Stats' : `New ${type}` }}
          </div>
          <v-spacer />
          <v-btn
            icon
            @click="modalOpen = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-4">
          <CalculatorStats
            v-if="modalTab === 0"
            :version-id="version._id"
          />
          <NewElementForm
            v-else
            :type="type"
            :adding-elements="addingElements"
            :event-key="eventKey"
            @add-elements="(elements, related, callback) => {
              modalOpen = false
              $emit('add-elements', elements, related, callback)
            }"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CalculatorStats from './CalculatorStats'
import NewElementForm from './NewElementForm'
import { createNamespacedHelpers } from 'vuex'
import BenefitsExpansionPanel from './benefits/BenefitsExpansionPanel'
const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')
const { mapGetters: mapVersionGetters } = createNamespacedHelpers('projects/versions')

export default {
  name: 'CalculatorLeftPanel',
  components: {
    CalculatorStats,
    NewElementForm,
    BenefitsExpansionPanel
  },
  props: {
    addingElements: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    eventKey: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      expansionPanels: [0],
      modalOpen: false,
      modalTab: 0
    }
  },
  computed: {
    ...mapProjectGetters(['project']),
    ...mapVersionGetters(['version']),
    typeIcon () {
      switch (this.type) {
        case 'material':
          return 'mdi-wall'
        case 'plant':
          return 'mdi-pine-tree'
        default:
          return 'mdi-shovel'
      }
    }
  },
  watch: {
    'type' (val, previousValue) {
      if (val === 'benefit') {
        this.expansionPanels = [1]
      }
      if (previousValue === 'benefit') {
        this.expansionPanels = [0]
      }
    }
  },
  methods: {
    canShowCPDVersion (version) {
      return ((!this.version.cpdVersions || this.version.cpdVersions.length === 0) && version === 2) ||
        (this.version.cpdVersions && this.version.cpdVersions.includes(version))
    }
  }
}
</script>

<style lang="scss">
.stats-panel .v-expansion-panel-content__wrap {
  padding: 0;
}
.new-element-form {
  position: relative;
  --box-shadow-color: var(--v-shamrock-base);
}
</style>
