<template>
  <div class="white pl-2 pr-2">
    <v-alert
      v-for="(error, index) in errors"
      :key="index"
      type="error"
      class="mx-3"
      flat
      dense
    >
      {{ error }}
    </v-alert>
    <v-btn
      class="detail button align-center mt-8 ml-4 mb-8"
      :outlined="true"
      tile
      color="shamrock white--text"
      elevation="0"
      @click="showExistingSiteConditions = true"
    >
      <v-icon
        left
        small
      >
        mdi-plus
      </v-icon>
      Add Biodiversity Site Conditions
    </v-btn>
    <v-expansion-panels
      v-model="panels"
      flat
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-2">
          <ExistingSiteConditions
            :visible="showExistingSiteConditions"
            @setVisible="val => showExistingSiteConditions = val"
          />
          <CategoryHeader>
            Ecosystem Protection
            <template v-slot:tooltip>
              Data Here
            </template>
          </CategoryHeader>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
            >
              <EcosystemPreservation
                v-if="hasExistingNativeEcosystem"
                class="net-impact"
                :series-data="ecosystemPreservationData"
              />
            </v-col>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pl-8 middle-align"
            >
              <div
                v-if="!hasExistingNativeEcosystem"
                class="mb-4"
              >
                No existing ecosystems. Add biodiversity site conditions information to measure ecosystem impacts.
                <ValueWithDataBook
                  :highlighted="true"
                  :color="'#00A25F'"
                  :tooltip="false"
                >
                  <template v-slot:variable-name>
                    Total Area Protected
                  </template>
                  <template v-slot:variable-value>
                    <SmartValue
                      :value="biodiversity.nativeEcosystemsPreservedTotalArea"
                      :from-unit="'m2'"
                      :to-imperial="'ft2'"
                      :to-metric="'m2'"
                      :format="'0,0'"
                    />
                  </template>
                </ValueWithDataBook>
              </div>
              <div
                v-else
                class="mb-4"
              >
                <div
                  v-if="preservationMet"
                  class="impact-met"
                >
                  You met the 30% protected minimum.
                </div>
                <div v-else>
                  You have not met the 30% protected minimum.
                </div>
                <div class="mt-8">
                  Ideally 100% should be protected.
                </div>
                <ValueWithDataBook
                  :highlighted="true"
                  :color="'#00A25F'"
                  :tooltip="false"
                >
                  <template v-slot:variable-name>
                    Total Area Protected
                  </template>
                  <template v-slot:variable-value>
                    <SmartValue
                      :value="biodiversity.nativeEcosystemsPreservedTotalArea"
                      :from-unit="'m2'"
                      :to-imperial="'ft2'"
                      :to-metric="'m2'"
                      :format="'0,0'"
                    />
                  </template>
                </ValueWithDataBook>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-2">
          <CategoryHeader>
            <template v-slot:tooltip>
              Data Here
            </template>
            Biodiversity Net Impact
          </CategoryHeader>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="ml-2">
          <v-row>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
            >
              <Impact
                class="net-impact"
                :impact-text="netImpactText"
                :impact-value="biodiversity.biodiversityNetImpact"
              />
            </v-col>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pl-8 middle-align"
            >
              <div class="mb-4">
                <div
                  v-if="biodiversityNetGainMet"
                  class="impact-met"
                >
                  You met the 10% biodiversity<br> net gain minimum.
                </div>
                <div v-else>
                  You have not met the 10%<br>biodiversity net gain minimum.
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CategoryHeader :decorated="false">
                Native Ecosystems
                <template v-slot:tooltip>
                  Data Here
                </template>
              </CategoryHeader>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pl-8 middle-align justify-start"
            >
              <div>
                <SmartValue
                  :value="biodiversity.nativeEcosystemsRemovedTotalArea"
                  :from-unit="'m2'"
                  :to-imperial="'ft2'"
                  :to-metric="'m2'"
                  :format="'0,0'"
                  class="impact-number"
                /> removed
                <div class="restored">
                  <SmartValue
                    :value="biodiversity.nativeEcosystemsRestoredTotalArea"
                    :from-unit="'m2'"
                    :to-imperial="'ft2'"
                    :to-metric="'m2'"
                    :format="'0,0'"
                    class="impact-number restored"
                  /> restored
                </div>
              </div>
            </v-col>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
            >
              <Impact
                class="net-impact tiny"
                :impact-text="netImpactText"
                :impact-value="biodiversity.nativeEcosystemImpacts"
              />
              <div class="middle-align">
                Native Ecosystem Impact
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CategoryHeader :decorated="false">
                Native Planting
                <template v-slot:tooltip>
                  Data Here
                </template>
              </CategoryHeader>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pl-8"
            >
              <v-row>
                <v-col><h4>Trees</h4></v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <SmartValue
                    :value="biodiversity.nativeTreesRemoved"
                    :format="'0,0'"
                    class="impact-number"
                  /> removed
                </v-col>
                <v-col>
                  <SmartValue
                    :value="biodiversity.nativeTreesRemovedPercent"
                    :format="'0,0'"
                    class="impact-number"
                    :append="'%'"
                  /> natives
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="planted">
                  <SmartValue
                    :value="biodiversity.nativeTreesPlanted"
                    :format="'0,0'"
                    class="impact-number"
                  /> planted
                </v-col>
                <v-col :class="{['impact-met']: biodiversity.nativePerennialsPercent >= 70}">
                  <SmartValue
                    :value="biodiversity.nativeTreesPlantedPercent"
                    :format="'0,0'"
                    class="impact-number"
                    :append="'%'"
                  /> natives
                </v-col>
              </v-row>
              <v-row>
                <v-col><h4>Shrubs</h4></v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="planted">
                  <SmartValue
                    :value="biodiversity.nativeShrubsPlanted"
                    :format="'0,0'"
                    class="impact-number"
                  /> planted
                </v-col>
                <v-col :class="{['impact-met']: biodiversity.nativePerennialsPercent >= 70}">
                  <SmartValue
                    :value="biodiversity.nativeShrubsPlantedPercent"
                    :format="'0,0'"
                    class="impact-number"
                    :append="'%'"
                  /> natives
                </v-col>
              </v-row>
              <v-row>
                <v-col><h4>Perennials</h4></v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="planted">
                  <SmartValue
                    :value="biodiversity.nativePerennialsPlantedTotalArea"
                    :format="'0,0'"
                    :from-unit="'m2'"
                    :to-imperial="'ft2'"
                    :to-metric="'m2'"
                    class="impact-number"
                  /> planted
                </v-col>
                <v-col :class="{['impact-met']: biodiversity.nativePerennialsPercent >= 70}">
                  <SmartValue
                    :value="biodiversity.nativePerennialsPercent"
                    :format="'0,0'"
                    class="impact-number"
                    :append="'%'"
                  /> natives
                </v-col>
              </v-row>
            </v-col>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="middle-align"
            >
              <div style="width: 100%;">
                <Impact
                  class="net-impact tiny"
                  :impact-text="netImpactText"
                  :impact-value="biodiversity.nativePlantingImpact"
                />
                <div class="middle-align">
                  Native Planting Impact
                </div>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-2">
          <CategoryHeader :capitalized="false">
            Habitats to Protect or Restore on Your Site
            <template v-slot:tooltip>
              Data Here
            </template>
          </CategoryHeader>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pl-8"
            />
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <div style="font-size: larger">
                Your site is within:
              </div>
              <div
                class="pl-5"
                style="color: var(--v-metal-base)"
              >
                Biome:
              </div>
              <EnrichedProjectConditions
                :show-biomes="true"
                :show-protected="false"
                :show-evapotranspiration="false"
                :show-underserved="false"
                :show-heat-island="false"
                :show-refresh="false"
              >
                <template v-slot:biome="biome">
                  {{ biome.biomeName }}
                </template>
              </EnrichedProjectConditions>
              <div
                class="pl-5"
                style="color: var(--v-metal-base)"
              >
                Ecoregion:
              </div>
              <EnrichedProjectConditions
                :show-biomes="true"
                :show-protected="false"
                :show-evapotranspiration="false"
                :show-underserved="false"
                :show-heat-island="false"
                :show-refresh="false"
              >
                <template v-slot:biome="biome">
                  {{ biome.ecoName }}
                </template>
              </EnrichedProjectConditions>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-2">
          <CategoryHeader>
            Protected Areas Near Your Site
            <template v-slot:tooltip>
              Data Here
            </template>
          </CategoryHeader>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col
              cols="12"
            >
              <EnrichedProjectConditions
                :show-biomes="false"
                :show-protected="true"
                :show-evapotranspiration="false"
                :show-underserved="false"
                :show-heat-island="false"
                :show-refresh="false"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import CategoryHeader from '@/components/atoms/CategoryHeader.vue'
import ExistingSiteConditions from '@/views/project/existingSiteConditions/ExistingSiteConditions.vue'

import Vue from 'vue'
import { debounce } from '@/helpers/debounce'
import Impact from '@/components/charts/Impact.vue'
import EcosystemPreservation from '@/components/charts/EcosystemPreservation.vue'
import ValueWithDataBook from '@/components/molecules/ValueWithDataBook.vue'
import SmartValue from '@/components/atoms/SmartValue.vue'
import EnrichedProjectConditions from '@/views/project/EnrichedProjectConditions.vue'
const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')
const { mapGetters: mapVersionGetters, mapActions: mapVersionActions } = createNamespacedHelpers('projects/versions')

export default Vue.extend({
  components: {
    EnrichedProjectConditions,
    ExistingSiteConditions,
    SmartValue,
    ValueWithDataBook,
    EcosystemPreservation,
    Impact,
    CategoryHeader
  },
  data () {
    return {
      geoJSON: null,
      panels: [0, 1],
      showExistingSiteConditions: false,
      featureLayerSrc: 'https://services5.arcgis.com/Mj0hjvkNtV7NRhA7/arcgis/rest/services/WDPA_v0/FeatureServer/1'
    }
  },
  computed: {
    ...mapProjectGetters([
      'project', 'hasExistingNativeEcosystem', 'isCEJSTUnderserved',
      'existingConditionOfNativeEcosystem']),
    ...mapVersionGetters(['version', 'biodiversity']),
    errors () {
      const ecosystemErrors = this.biodiversity?.ecosystemErrors || []
      const treesErrors = this.biodiversity?.treesErrors || []
      const errors = []
      if ([...ecosystemErrors, ...treesErrors].findIndex((error) => error.errors.includes('EXISTING_MISSING')) > -1) {
        errors.push(this.$t('biodiversity.errors.existingMissing'))
      }
      if ([...ecosystemErrors, ...treesErrors].findIndex((error) => error.errors.includes('TOO_LARGE')) > -1) {
        errors.push(this.$t('biodiversity.errors.tooLarge'))
      }
      return errors
    },
    preservationMet () {
      return this.biodiversity.nativeEcosystemsPreservedPercent >= 30
    },
    biodiversityNetGainMet () {
      return this.biodiversity.biodiversityNetImpact >= 10
    },
    ecosystemPreservationData () {
      if (this.hasExistingNativeEcosystem) {
        return [
          {
            name: 'Protected',
            y: this.biodiversity.nativeEcosystemsPreservedPercent,
            color: '#00A25F'
          },
          {
            name: 'Removed',
            y: this.biodiversity.nativeEcosystemsRemovedPercent,
            color: '#8a97a2'
          }
        ]
      } else {
        return [
          {
            name: 'Native Ecosystems Protected',
            y: this.biodiversity.nativeEcosystemsPreservedPercent,
            color: '#00A25F'
          },
          { name: 'Native Ecosystems Removed', y: 0, color: '#8a97a2' }
        ]
      }
    }
  },
  async created () {
    const { data } = await this.$axios.get(`/projects/${this['project']._id}/location`)
    this.geoJSON = data
  },
  methods: {
    ...mapVersionActions(['fetchVersion', 'setVersion']),
    mergeAttributes () {
      return {
        ...this['version'].attributes
      }
    },
    netImpactText: function (value) {
      if (value >= 0) {
        return 'Net Gain'
      } else {
        return 'Net Loss'
      }
    },
    updateServer: debounce(async function (partial) {
      await this.$axios.put(`/versions/${this['version']._id}`, partial)
      await this.fetchVersion({ id: this['version']._id })
    }, 1000)
  }
})
</script>

<style lang="scss">
.net-impact {
  height: 180px !important;
}
.tiny {
  height: 140px !important;
}
.impact-number {
  font-size: larger;
}
.planted {
  color: var(--v-shamrock-base);
}
.restored {
  color: var(--v-shamrock-base);
}
.impact-met {
  color: var(--v-shamrock-base);
  font-size: larger;
}
</style>
