<template>
  <v-card
    height="700"
    class="shrub-form"
  >
    <v-card-title>
      Add shrubs
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="pb-0">
          <div>
            <span class="input-label">
              Area
            </span>
            <TextFieldAndSlider
              :initial-value="0"
              :max="projectArea"
              icon="mdi-ruler-square"
              :suffix="systemOfMeasurement === 'metric' ? 'm2' : 'sf'"
              show-slider
              @change="val => setArea(val)"
            />
          </div>
          <div class="mt-5">
            <span
              class="input-label"
              style="position: relative; top: 5px;"
            >Foliage</span>
            <div class="d-flex align-center">
              <strong style="font-size: 13px; margin-right: 15px; color: #e4b96e">
                Deciduous {{ percentDeciduous }}%
              </strong>
              <v-slider
                v-model="percentDeciduous"
                hide-details
                class="mt-0 slider primary--text decidious-evergreen"
                :step="1"
                :min="0"
                :max="100"
                height="50"
              />
              <strong
                style="font-size: 13px; margin-left: 15px;"
                class="shamrock--text"
              >
                {{ 100 - percentDeciduous }}% Evergreen
              </strong>
            </div>
          </div>
          <div class="mt-3">
            <span class="input-label">
              Shrub sizes
            </span>
            <ul class="size-ref mt-2">
              <li>Small - Mature height below 3ft (1m) - 24” spacing (0.6m)</li>
              <li>Medium - Mature height 3-6ft (1-2m) - 42” spacing (1.1m)</li>
              <li>Large - Mature height above 6ft (2m) - 60” spacing (1.5m)</li>
            </ul>
          </div>
          <VueSlider
            v-model="sizeSliderValues"
            :drag-on-click="true"
            :enable-cross="false"
            :dot-options="dotOptions"
            :dot-size="24"
            :height="40"
            :process="process"
          >
            <template v-slot:dot>
              <div
                ref="slider-dot"
                class="vue-slider-dot-handle"
              />
            </template>
          </VueSlider>
          <div class="mt-5">
            <span class="input-label">
              Calculated shrub quantities
            </span>
            <v-data-table
              hide-default-footer
              :headers="tableHeaders"
              :items="tableData"
              class="elevation-1 mt-3"
            >
              <template v-slot:item.small="{ item }">
                {{ item.small[0] }}
              </template>
              <template v-slot:item.medium="{ item }">
                {{ item.medium[0] }}
              </template>
              <template v-slot:item.large="{ item }">
                {{ item.large[0] }}
              </template>
            </v-data-table>
          </div>
          <div class="d-flex justify-end mt-5">
            <v-btn
              color="error"
              class="mx-3"
              dark
              elevation="0"
              text
              @click="$emit('discard')"
            >
              Discard
            </v-btn>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  :color="area === 0 ? 'secondary' : 'shamrock'"
                  outlined
                  elevation="0"
                  :loading="addingShrubs"
                  :style="{ cursor: area === 0 ? 'default' : 'pointer' }"
                  @click="area === 0 ? undefined : $emit('save', quantities)"
                  v-on="area === 0 ? on : undefined"
                >
                  Confirm
                </v-btn>
              </template>
              Increase the planted area to calculate shrub quantities, or click discard and add shrub quantities manually
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TextFieldAndSlider from '@/components/atoms/TextFieldAndSlider'
import VueSlider from 'vue-slider-component'
import { squareMetresToSquareFeet, squareFeetToSquareMetres } from '@/helpers/unitConversions'

import { createNamespacedHelpers, mapState } from 'vuex'
const { mapState: mapProjectState } = createNamespacedHelpers('projects')

export default {
  name: 'ShrubForm',
  components: {
    TextFieldAndSlider,
    VueSlider
  },
  props: {
    addingShrubs: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      percentDeciduous: 50,
      dotOptions: [{ disabled: true }, null, null, { disabled: true }],
      sizeSliderValues: [0, 33, 67, 100],
      sizes: {
        small: 33,
        medium: 34,
        large: 33
      },
      area: 0,
      missingValues: [],
      sizeColors: ['#8BD4B6', '#52c194', '#00a25f'],
      process: dotsPos => [
        [dotsPos[0], dotsPos[1], { backgroundColor: this.sizeColors[0] }],
        [dotsPos[1], dotsPos[2], { backgroundColor: this.sizeColors[1] }],
        [dotsPos[2], dotsPos[3], { backgroundColor: this.sizeColors[2] }]
      ],
      tableHeaders: [
        { sortable: false, value: 'name' },
        { text: 'Small', sortable: false, align: 'center', value: 'small' },
        { text: 'Medium', sortable: false, align: 'center', value: 'medium' },
        { text: 'Large', sortable: false, align: 'center', value: 'large' }
      ]
    }
  },
  computed: {
    ...mapState(['systemOfMeasurement']),
    ...mapProjectState(['project']),
    projectArea () {
      if (this.systemOfMeasurement === 'metric') {
        return this.project.location.area
      } else {
        return squareMetresToSquareFeet(this.project.location.area)
      }
    },
    tableData () {
      const areaInSquareInches = this.area * 1550
      // old way is this:
      // Large plant area, based on 60” oc triangular spacing: 1000sf @ 60” oc
      // triangular = (1000x144 (to get to sq. inches))/(60x(60x0.866)) = 144,000/3117.6 = 46.189 plants
      // in 1000sf planting area. 144,000 sq in / 46.189 plants = 3117.625 sq in per plant
      // (for triangular spacing, the second row is 0.866 the spacing distance away from the first)
      //
      // const squareInchesPerPlant = {
      //   small: 498.816,
      //   medium: 1560.739,
      //   large: 3117.625
      //  }

      // the new way is to simply use the area per plant
      // https://climatepositivedesign.atlassian.net/browse/PAT-261
      // 0.2918 m2 = 452.4459 in2
      // 0.8938 m2 = 1385.3928 in2
      // 1.8242 m2 = 2827.51566 in2
      const squareInchesPerPlant = {
        small: 452.4459,
        medium: 1385.3928,
        large: 2827.51566
      }
      const totalQuantities = Object.entries(this.sizes).map(([key, value]) => {
        const decimal = value / 100
        const quantity = (decimal * areaInSquareInches) / squareInchesPerPlant[key]
        return [key, quantity]
      })
      return [
        {
          name: 'Deciduous',
          ...Object.fromEntries(totalQuantities.map(([key, quantity]) =>
            this.mapV3Elements(key, 'deciduous', Math.round(quantity * this.percentDeciduous * 0.01))))
        },
        {
          name: 'Evergreen',
          ...Object.fromEntries(totalQuantities.map(([key, quantity]) =>
            this.mapV3Elements(key, 'evergreen', Math.round(quantity * this.percentDeciduous * 0.01))))
        }
      ]
    },
    quantities () {
      return Object.fromEntries(this.tableData.map(type => {
        const key = type.name.toLowerCase()
        delete type.name
        return [key, type]
      }))
    }
  },
  watch: {
    sizeSliderValues: {
      handler (val) {
        this.setSizes(val)
      }
    }
  },
  mounted () {
    this.setSizes(this.sizeSliderValues)
  },
  methods: {
    mapV3Elements (key, plantType, quantity) {
      if (key === 'small' && plantType === 'deciduous') {
        return [key, [quantity, 'PS-SD']]
      }
      if (key === 'medium' && plantType === 'deciduous') {
        return [key, [quantity, 'PS-MD']]
      }
      if (key === 'large' && plantType === 'deciduous') {
        return [key, [quantity, 'PS-LD']]
      }
      if (key === 'small' && plantType === 'evergreen') {
        return [key, [quantity, 'PS-SE']]
      }
      if (key === 'medium' && plantType === 'evergreen') {
        return [key, [quantity, 'PS-ME']]
      }
      if (key === 'large' && plantType === 'evergreen') {
        return [key, [quantity, 'PS-LE']]
      }
    },
    setArea (val) {
      if (this.systemOfMeasurement === 'metric') {
        this.area = val
      } else {
        this.area = squareFeetToSquareMetres(val)
      }
    },
    setSizes (v) {
      var sizes = {
        small: v[1],
        medium: v[2] - v[1],
        large: v[3] - v[2]
      }
      if (this.$refs['slider-dot']) {
        for (var name of Object.keys(sizes)) {
          for (var j = 0; j < 3; j++) {
            this.$refs['slider-dot'].parentNode.parentNode.children[j].style.setProperty(`--${name}`, `"${sizes[name]}% ${name}"`)
          }
        }
        this.sizes = sizes
      }
    }
  }
}
</script>

<style lang="scss">
.shrub-form {
  .input-label {
    transform: scale(0.9) translateY(3px);
    transform-origin: left;
    white-space: nowrap;
    margin-bottom: 6px;
    color: var(--v-metal-darken2);
  }
  .vue-slider-process {
    overflow: hidden;
    border-radius: 0 !important;
    &::after {
      content: var(--small);
      text-align: center;
      position: absolute;
      line-height: 1.25;
      color: #fff;
      left: 50%;
      top: 50%;
      width: 75%;
      transform: translateX(-50%) translateY(-50%);
    }
    &:nth-of-type(1) {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
    &:nth-of-type(3) {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
    &:nth-of-type(2)::after { content: var(--medium); }
    &:nth-of-type(3)::after { content: var(--large); }
  }
  .vue-slider-rail {
    // background-color: transparent !important;
    .vue-slider-dot {
      height: 50px !important;
      width: 8px !important;
      &:nth-of-type(4), &:nth-of-type(7) {
        display: none;
      }
      .vue-slider-dot-tooltip {
        display: none !important;
      }
      .vue-slider-dot-handle {
        border-radius: 3px;
        background-color: var(--v-primary-base) !important;
        position: relative;
        box-shadow: none;
      }
    }
  }

  .size-ref {
    li {
      margin-bottom: 7px;
    }
  }

  .v-input.slider.decidious-evergreen {
    .v-slider__track-container {
      height: 2px;
      transform: scaleY(4)!important;
    }
    .v-slider__track-background {
      background-color: #8bd4b6 !important;
      opacity: 1;
    }
    .v-slider__track-fill {
      background-color: #e4b96e !important;
    }
    .v-slider__thumb {
      height: 23px;
      width: 23px;
      transform: translateY(calc(1px - 50%));
      cursor: pointer;
      &::before {
        display: none;
      }
    }
  }

  .co2-loader {
    margin: 0;
    height: 7px;
    width: 50px;
    display: inline-block;
    margin-right: 3px;
  }

  .growthzones{
    max-width: 100%;
  }

  .composite-input *{
    min-width: 70px !important
  }
}
</style>
