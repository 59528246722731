






























import WaterBenefitsTab from './WaterBenefitsTab.vue'
import HeatRiskBenefitsTab from './HeatRiskBenefitsTab.vue'
import Vue from 'vue'
import EquityBenefitsTab from '@/views/calculator/calculatorTab/benefits/EquityBenefitsTab.vue'
import BiodiversityBenefitsTab from '@/views/calculator/calculatorTab/benefits/BiodiversityBenefitsTab.vue'
import References from '@/views/calculator/References.vue'

export default Vue.extend({
  components: {
    References,
    BiodiversityBenefitsTab,
    EquityBenefitsTab,
    WaterBenefitsTab,
    HeatRiskBenefitsTab
  },
  props: {
    projectId: {
      type: String,
      default: null
    },
    isActive: {
      type: Boolean,
      required: true
    },
    versionId: {
      type: String,
      default: null
    },
    subtype: {
      type: String,
      default: null
    }
  }
})
