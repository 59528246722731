<template>
  <v-col
    cols="12"
    md="6"
    :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
  >
    <v-card
      class="white"
      flat
      elevation="1"
    >
      <v-card-text>
        <div class="headline shamrock--text">
          {{ headline }}
        </div>
        <div class="metal--text">
          {{ description }}
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'HighLevelSummary',
  props: {
    headline: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>

</style>
