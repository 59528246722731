export default {
  trees: [
    {
      growthZone: 'north',
      sequestration: [0.05, 0.18, 0.34, 0.44, 0.47, 0.46, 0.43, 0.4],
      decomposition: [-0.0008, -0.004, -0.001, -0.002, -0.0033, -0.0046, -0.006, -0.0075],
      replanting: [0, 0, 0.15]
    },
    {
      growthZone: 'central',
      sequestration: [0.08, 0.28, 0.48, 0.58, 0.58, 0.51, 0.44, 0.35],
      decomposition: [-0.0006, -0.0005, -0.0013, -0.0025, -0.0038, -0.0052, -0.0064, -0.0075],
      replanting: [0, 0, 0.15]
    },
    {
      growthZone: 'south',
      sequestration: [0.07, 0.3, 0.51, 0.6, 0.57, 0.48, 0.38, 0.28],
      decomposition: [-0.0005, -0.0005, -0.0014, -0.0027, -0.0041, -0.0054, -0.0066, -0.0075],
      replanting: [0, 0, 0.15]
    }
  ],
  shrubs: [
    {
      growthZone: 'north',
      sequestration: [0.34, 0.43],
      decomposition: [-0.001, -0.006],
      replanting: [1, 1]
    },
    {
      growthZone: 'central',
      sequestration: [0.48, 0.44],
      decomposition: [-0.0013, -0.0064],
      replanting: [1, 1]
    },
    {
      growthZone: 'south',
      sequestration: [0.51, 0.38],
      decomposition: [-0.0014, -0.0066],
      replanting: [1, 1]
    }
  ]
}
