<template>
  <div>
    <div>
      <template v-if="showBiomes">
        <v-chip
          v-for="biome in biomes"
          :key="biome.id"
          :loading="isLoading"
          :disabled="isLoading"
          color="leaf primary--text"
          class="font-weight-medium mx-2 mb-3"
        >
          <slot
            name="biome"
            v-bind="biome"
          >
            {{ $t('project.geolocationData.biome', biome) }}
          </slot>
        </v-chip>
      </template>
      <template v-if="showProtected">
        <v-chip
          v-for="protectedArea in protectedAreas"
          :key="protectedArea.id"
          :loading="isLoading"
          :disabled="isLoading"
          color="leaf primary--text"
          class="font-weight-medium mx-2 mb-3"
        >
          {{ protectedArea.cpdName }}
        </v-chip>
      </template>
      <template v-if="showEvapotranspiration">
        <v-chip
          v-if="evapotranspiration"
          :loading="isLoading"
          :disabled="isLoading"
          color="leaf primary--text"
          class="font-weight-medium mx-2 mb-3"
        >
          <SmartValue
            :i18n="{ key: 'project.geolocationData.eto', values: evapotranspiration }"
            :value="evapotranspiration"
            :from-unit="fromUnit"
            :to-imperial="'in'"
            :to-metric="'mm'"
          />
        </v-chip>
      </template>
      <v-chip
        v-if="isLocatedInHeatIsland && showHeatIsland"
        :loading="isLoading"
        :disabled="isLoading"
        color="leaf primary--text"
        class="font-weight-medium mx-2 mb-3"
      >
        Severe Heat Area
      </v-chip>
      <v-chip
        v-if="isCEJSTUnderserved && showUnderserved"
        :loading="isLoading"
        :disabled="isLoading"
        color="leaf primary--text"
        class="font-weight-medium mx-2 mb-3"
      >
        Underserved Community
      </v-chip>
    </div>
    <v-btn
      v-if="user.isAdmin && showRefresh"
      class="detail button align-center mt-2 mb-4"
      :outlined="true"
      tile
      color="shamrock white--text"
      elevation="0"
      :loading="isLoading"
      :disabled="isLoading"
      @click="enrichData"
    >
      <v-icon
        left
        small
      >
        mdi-reload
      </v-icon>
      Refresh Project Conditions
    </v-btn>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapActions as mapGlobalActions } from 'vuex'
import unitTools from '@/mixins/unitTools'
import SmartValue from '@/components/atoms/SmartValue.vue'
import { reactive } from '@vue/composition-api'
const { mapGetters: mapProjectGetters, mapActions: mapProjectActions } = createNamespacedHelpers('projects')
const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

const apptAccess = reactive({})

export default {
  name: 'EnrichedProjectConditions',
  components: {
    SmartValue
  },
  mixins: [unitTools],
  props: {
    showBiomes: {
      type: Boolean,
      required: false,
      default: true
    },
    showProtected: {
      type: Boolean,
      required: false,
      default: false
    },
    showRefresh: {
      type: Boolean,
      required: false,
      default: true
    },
    showEvapotranspiration: {
      type: Boolean,
      required: false,
      default: true
    },
    showUnderserved: {
      type: Boolean,
      required: false,
      default: true
    },
    showHeatIsland: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapProjectGetters(['project', 'evapotranspiration', 'isLocatedInHeatIsland', 'hasHeatSeverityStatistics', 'isCEJSTUnderserved']),
    ...mapAuthGetters(['user']),
    biomes () {
      return this.project.enrichedData?.biomes?.parsedResponse || []
    },
    protectedAreas () {
      return this.project.enrichedData?.protectedAreas100km?.parsedResponse || []
    },
    fromUnit () {
      return 'mm'
    },
    all () {
      return [...this.biomes, ...this.protectedAreas]
    },
    isLoading () {
      return this.loading || this.project.enrichingData
    }
  },
  methods: {
    ...mapGlobalActions(['showSnackbar']),
    ...mapProjectActions(['enrichProject']),
    async enrichData () {
      this.loading = true
      try {
        await this.enrichProject()
      } catch (err) {
        await this.showSnackbar({ color: 'error', text: 'Unable to change active version. Please get in touch.' })
        throw err
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
