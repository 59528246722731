












































export default {
  name: 'References',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    subtype: {
      type: String,
      default: null
    }
  }
}
