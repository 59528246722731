<template>
  <!-- show skeleton loader for each element -->
  <div
    ref="wrapper"
    style="position: relative;"
  >
    <v-banner
      v-if="!loading"
      ref="banner"
      style="background: #fff; z-index: 1; top: 0; left: 0; width: 100%;"
      :style="{ position: sheetIsEmpty ? 'relative' : 'absolute' }"
    >
      <div
        v-if="sheetIsEmpty"
        class="body-2"
      >
        Nothing here yet. {{ $vuetify.breakpoint.smAndUp ? `Use the new ${typeToDisplay} form` : `Click "new ${typeToDisplay}"` }} to get started.
      </div>
      <div
        v-else
        class="d-flex align-center"
        :class="$vuetify.breakpoint.xsOnly ? 'body-2' : ['body-1', 'mt-2']"
      >
        <div
          v-for="total in typeTotal"
          :key="total.text"
        >
          <span class="mr-2">{{ total.text }}:</span>
          <div
            class="d-flex align-center"
            :style="{ color: total.color }"
          >
            <v-progress-linear
              v-if="statsLoading"
              color="metal"
              class="mr-2"
              style="width: 50px; height: 8px;"
              indeterminate
            />
            <strong
              v-else
              class="mr-1 title"
            >{{ total.value.toLocaleString() }}</strong>
            <span style="margin-top: 1px; white-space: nowrap;">
              kgCOe₂
            </span>
          </div>
        </div>
      </div>
    </v-banner>
    <v-list
      v-if="loading"
      class="leaf"
      :style="{
        'padding-top': loading ? 0 : '73px',
        'margin-top': loading ? '-15px' : 0
      }"
    >
      <v-skeleton-loader
        v-for="n in 3"
        :key="n"
        type="list-item-two-line"
        class="mx-auto leaf"
      />
    </v-list>
    <div
      v-else-if="!sheetIsEmpty"
      class="white"
      style="padding-top: 100px; overflow: auto;"
      :style="{ height: $vuetify.breakpoint.xsOnly ? 'calc(100vh - 160px)' : 'auto' }"
    >
      <div
        v-if="globalVariables && globalVariables.length > 0"
        class="variables px-3 pt-0"
      >
        <VariableInput
          v-for="variable of globalVariables"
          :key="variable._id"
          :variable="variable"
          @updated="handleVariableUpdated()"
        />
        <div
          class="mt-4 mb-5"
          style="width: 100%; border-bottom: 1px solid #ccc;"
        />
      </div>
      <CalculatorCategory
        v-for="(category, index) of categories"
        :key="category.name"
        :category="category"
        :is-active="isActive"
        :show-bottom-border="index < categories.length - 1"
        @variable-updated="handleVariableUpdated()"
        @element-deleted="id => handleElementDeleted(id)"
        @element-updated="id => handleElementUpdated(id)"
        @element-updating="id => handleElementUpdating(id)"
      />
    </div>
    <References
      :loading="loading"
      :type="type"
      :subtype="subtype"
    />
  </div>
</template>

<script>
import CalculatorCategory from './CalculatorCategory'
import VariableInput from './VariableInput'
import References from '../References.vue'
import { mapState, mapActions, createNamespacedHelpers } from 'vuex'
const { mapGetters: mapVersionGetters, mapMutations: mapVersionMutations } = createNamespacedHelpers('projects/versions')
const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')

export default {
  name: 'CalculatorTab',
  components: {
    CalculatorCategory,
    VariableInput,
    References
  },
  props: {
    type: {
      type: String,
      default: null
    },
    isActive: {
      type: Boolean,
      required: true
    },
    eventKey: {
      type: Number,
      default: null
    },
    subtype: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      co2Values: [],
      categories: []
    }
  },
  computed: {
    ...mapProjectGetters(['project']),
    ...mapState(['systemOfMeasurement']),
    ...mapVersionGetters(['version', 'statsLoading', 'formDisabled']),
    elements () {
      return this.categories.flatMap(({ elements }) => elements || [])
    },
    typesForFilters () {
      if (this.type === 'operation') {
        return ['maintenance', 'hydrozone']
      } else if (this.type === 'plant') {
        return ['plant']
      } else {
        return [this.type]
      }
    },
    typeToDisplay () {
      return this.type === 'maintenance' ? 'operation' : this.type
    },
    sheetIsEmpty () {
      return !this.loading && this.categories.length === 0
    },
    typeTotal () {
      const textLookup = {
        material: 'Total material impact',
        // materialStored: 'Stored carbon ',
        plant: 'Total plant impact',
        // plantStored: 'Stored carbon',
        operation: 'Total operational impact'
      }
      if (!this.version || !this.version.stats.yearByYear) {
        return { text: textLookup[this.type], value: 0, color: 'var(--v-metal-base)' }
      }
      let value = Math.round(this.elements.reduce((acc, element) => acc + element.totalCo2Kg, 0))
      const colorLookup = {
        0: 'var(--v-metal-base)',
        1: 'rgb(220, 14, 21)',
        '-1': 'var(--v-shamrock-base)'
      }
      const storedCo2Kg = this.elements.reduce((acc, element) => acc + element.storedCo2Kg, 0)
      const operationalCo2Kg = this.elements.reduce((acc, element) => acc + (element.operationalCo2Kg || 0), 0)
      const waterRelatedOperationCo2Kg = this.elements.reduce((acc, element) => acc + (element.totalWaterUseCO2Impact || 0), 0)

      if (this.type === 'operation' && this.version.stats.other && this.version.stats.other.installationOfTreesImpactInTonnes) {
        value += Math.round(this.version.stats.other.installationOfTreesImpactInTonnes * 1000)
      }
      if (this.type === 'operation' && this.version.stats.other && this.version.stats.other.installationOfForestImpactInTonnes) {
        value += Math.round(this.version.stats.other.installationOfForestImpactInTonnes * 1000)
      }

      if (this.type === 'operation' && waterRelatedOperationCo2Kg) {
        value += Math.round(waterRelatedOperationCo2Kg)
      }

      const impact = {
        text: textLookup[this.type],
        value: Math.abs(value),
        color: colorLookup[Math.sign(value).toString()]
      }

      const carbonStored = (textLookup[this.type + 'Stored'] && [{
        text: textLookup[this.type + 'Stored'],
        value: Math.abs(storedCo2Kg),
        color: 'var(--v-metal-base)'
      }]) || []

      const operational = (textLookup[this.type + 'Operational'] && [{
        text: textLookup[this.type + 'Operational'],
        value: Math.abs(operationalCo2Kg),
        color: 'var(--v-metal-base)'
      }]) || []

      return [
        impact,
        ...carbonStored,
        ...operational
      ]
    },
    globalVariables () {
      if (this.type === 'plant') {
        const growthZone = this.version.variables.find(variable => variable.name === 'growth_zone')
        return [{
          ...growthZone,
          isVariable: true,
          image: require('@/assets/growthzones.jpg'),
          relatedCategory: null,
          _id: 0
        }]
      }
      return null
    }
  },
  watch: {
    isActive (val) {
      if (val) {
        this.fetchElements()
      }
    },
    eventKey (val) {
      if (this.isActive) {
        this.fetchElements()
      }
    }
  },
  created () {
    setTimeout(() => {
      this.fetchElements()
    }, 300)
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapVersionMutations(['setHasShrubs', 'setFormDisabled']),
    ...mapActions(['showSnackbar']),
    handleScroll () {
      // all tabs are enabled regardless of its visibility.
      // only handles scrolls if the tab is active
      if (this.isActive) {
        let { top } = this.$refs.wrapper.getBoundingClientRect()
        top -= 48 // account for toolbar height
        const bannerTransform = Math.max(0, -top)
        if (this.$refs.banner) {
          this.$refs.banner.$el.style.transform = `translateY(${bannerTransform}px)`
        }
      }
    },
    async fetchElements () {
      try {
        const { data: categories } = await this.$axios.get(`/versions/${this.version._id}/categories`, {
          params: {
            type: this.typesForFilters,
            system: this.systemOfMeasurement
          }
        })

        // add installation of trees impact manually
        if (this.type === 'operation' && this.version.stats.other?.installationOfTreesImpactInTonnes > 0) {
          categories.push({
            name: 'Installation of new trees',
            meta: {
              installationOfTreesImpactInTonnes: Math.round(this.version.stats.other.installationOfTreesImpactInTonnes * 1000)
            }
          })
        }
        // installation of natural forest impact
        if (this.type === 'operation' && this.version.stats.other?.installationOfForestImpactInTonnes > 0) {
          categories.push({
            name: 'Installation of natural forest',
            meta: {
              installationOfForestImpactInTonnes: Math.round(this.version.stats.other.installationOfForestImpactInTonnes * 1000)
            }
          })
        }

        this.categories = categories
        this.loading = false
      } catch (err) {
        this.showSnackbar({ color: 'error', text: 'Unable to load version elements. Redirecting to project overview.' })
        this.$router.push(`/projects/${this.project._id}`)
        throw err
      }
    },
    async fetchQuantities () {
      this.setFormDisabled(true)
      const { data: newElementData } = await this.$axios.get(`/versions/${this.version._id}/elements`, {
        params: {
          type: this.typesForFilters,
          system: this.systemOfMeasurement,
          select: 'totalQuantity totalCo2Kg shippingCo2Kg emissionsCo2Kg storedCo2Kg operationalCo2Kg totalWaterUse totalWaterUseCO2Impact layouts'
        }
      })
      // updating existing elements with the new totalQuantity and totalCo2Kg field values
      this.categories = this.categories.map(({ elements, ...category }) => ({
        ...category,
        elements: elements.map(existingElement => {
          const newElement = newElementData.find(element => element._id === existingElement._id)
          return {
            ...existingElement,
            ...newElement
          }
        })
      }))
      this.setFormDisabled(false)
    },
    handleVariableUpdated () {
      this.$emit('updating')
      this.fetchQuantities()
      this.$emit('updated')
    },
    handleElementDeleted (elementId) {
      this.$emit('updating')
      const { categoryIndex, elementIndex } = this.getElementById(elementId)
      this.$delete(this.categories[categoryIndex].elements, elementIndex)
      this.$emit('updated')
      this.fetchElements()
    },
    getElementById (elementId) {
      const searchResults = this.categories.map(({ elements }) => {
        if (elements && Array.isArray(elements)) { // check for elements like 'Instalation trees' on operation tab
          const elementIndex = elements.findIndex((element) => element._id === elementId)
          return { elementIndex, element: elements[elementIndex] }
        } else {
          return { elementIndex: -1, element: null }
        }
      })
      const categoryIndex = searchResults.findIndex(({ elementIndex }) => elementIndex !== -1)
      return { categoryIndex, ...searchResults[categoryIndex] }
    },
    async handleElementUpdating () {
      this.$emit('updating')
    },
    async handleElementUpdated (elementId) {
      const { data: { element: newQuantities } } = await this.$axios.get(`/elements/${elementId}`, {
        params: {
          select: 'totalQuantity emissionsCo2Kg shippingCo2Kg totalCo2Kg operationalCo2Kg storedCo2Kg totalWaterUse totalWaterUseCO2Impact',
          system: this.systemOfMeasurement
        }
      })
      // update element with new totalQuantity and totalCo2Kg field values
      const { categoryIndex, elementIndex, element } = this.getElementById(elementId)
      this.$set(this.categories[categoryIndex].elements, elementIndex, {
        ...element,
        ...newQuantities
      })
      if (element.category === 'walls, curbs & headers') {
        this.$store.dispatch('showSnackbar', 'Add cladding, foundation, or steel reinforcements from the new element panel.')
      }
      if (element.category === 'paving' && element.name.toLowerCase().includes('concrete')) {
        this.$store.dispatch('showSnackbar', 'Add steel reinforcements to your concrete paving from the new element panel.')
      }
      this.$emit('updated')
    }
  }
}
</script>
