





































import { createNamespacedHelpers } from 'vuex'
import worldIcon from '@/assets/icons/world'
import Vue from 'vue'

const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')
const { mapGetters: mapVersionGetters } = createNamespacedHelpers('projects/versions')
const BENEFITS_TABS = ['landing', 'biodiversity', 'water', 'cooling', 'equity']

export default Vue.extend({
  name: 'BenefitsExpansionPanel',
  props: {
    projectId: {
      type: String,
      default: null
    },
    versionId: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      activeTab: null,
      worldIcon: worldIcon,
      tabs: BENEFITS_TABS
    }
  },
  computed: {
    ...mapProjectGetters(['project']),
    ...mapVersionGetters(['version', 'statsLoading'])
  },
  methods: {
    setSubtype (index: number) {
      this.$emit('set-subtype', this.tabs?.[index])
    }
  }
})

