import { render, staticRenderFns } from "./BenefitsExpansionPanel.vue?vue&type=template&id=241661ae&scoped=true&"
import script from "./BenefitsExpansionPanel.vue?vue&type=script&lang=ts&"
export * from "./BenefitsExpansionPanel.vue?vue&type=script&lang=ts&"
import style0 from "./BenefitsExpansionPanel.vue?vue&type=style&index=0&id=241661ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "241661ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VTab,VTabs})
