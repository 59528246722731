<template>
  <v-sheet>
    <ProjectStats
      :version-id="versionId"
    />
    <div class="d-flex justify-end mx-5">
      <ScorecardDialog :version-id="versionId">
        <template #activator="{ on }">
          <v-btn
            class="mb-5"
            outlined
            rounded
            text
            color="onyx"
            elevation="0"
            :loading="downloading"
            v-on="$vuetify.breakpoint.mdAndUp ? on : undefined"
            @click="downloadScorecardIfSmall"
          >
            {{ $vuetify.breakpoint.mdAndUp ? 'View' : 'Download' }} scorecard
          </v-btn>
        </template>
      </ScorecardDialog>
    </div>
  </v-sheet>
</template>

<script>
import ProjectStats from '@/components/application/projectStats/ProjectStats'
import ScorecardDialog from '@/components/modals/ScorecardDialog'

export default {
  name: 'CalculatorStats',
  components: {
    ProjectStats,
    ScorecardDialog
  },
  props: {
    versionId: {
      type: String,
      default: null
    }
  }
}
</script>
